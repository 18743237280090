import Snackbar from "@material-ui/core/Snackbar";
import * as React from "react";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";
import { AppSelectors } from "../store/app/app-selectors";
import { useSelector } from "react-redux";
import { useDispatchTs } from "../store";
import { AppActions } from "../store/app/app-actions";

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export const Alerts = () => {
  const dispatch = useDispatchTs();
  const snack = useSelector(AppSelectors.snack);
  const version = useSelector(AppSelectors.getVersion);
  const onClose = () => dispatch(AppActions.setSnack(""));

  const getMessage = (snack) => {
    switch (snack) {
      case "newUser":
        return "Käyttäjä tallennettu. Kirjaudu sisään!";
      case "newVersion":
        return `Uusi versio päivitetty v${version}`;
    }
  };

  return (
    <Snackbar open={snack !== ""} autoHideDuration={6000} onClose={onClose}>
      <Alert onClose={() => onClose()} severity="success">
        {getMessage(snack)}
      </Alert>
    </Snackbar>
  );
};
