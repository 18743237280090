import * as React from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import AccessibleForwardIcon from "@material-ui/icons/AccessibleForward";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { useSelector } from "react-redux";
import { ApplicationState } from "../store";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
  grow: {
    flexGrow: 1,
  },
  link: {
    textDecoration: "none",
    color: "#fff",
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  loginActionButtons: {
    color: "white",
  },
});

function ButtonAppBar() {
  const { loggedIn } = useSelector((state: ApplicationState) => state.auth);
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" color="inherit" className={classes.grow}>
            <Link className={classes.link} to={"/"}>
              Automobile
            </Link>
          </Typography>
          {loggedIn && (
            <Link
              title="Kirjaudu ulos"
              className={classes.loginActionButtons}
              to="/logout"
            >
              <ExitToAppIcon />
            </Link>
          )}
          {!loggedIn && (
            <Link
              title="Kirjaudu sisään"
              className={classes.loginActionButtons}
              to={"/login"}
            >
              <AccessibleForwardIcon />
            </Link>
          )}
        </Toolbar>
      </AppBar>
    </div>
  );
}

export default ButtonAppBar;
