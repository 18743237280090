import React, { FC } from 'react';
import BriefDetails from '../../components/BriefDetails';
import UserRights from '../../components/userRights/UserRights';
import { useSelectorTs } from '../../store';
import { VehicleSelectors } from '../../store/vehicles/vehicle-selectors';
import VehicleStatusHistory from "../../components/vehicleStatusHistory/vehicleStatusHistory";

const Settings: FC = () => {
  const currentVehicle = useSelectorTs(VehicleSelectors.getVehicle);
  if (!currentVehicle) return null;
  return (
    <div>
      <BriefDetails vehicle={currentVehicle} />
        <VehicleStatusHistory vehicle={currentVehicle}/>
      <UserRights />
    </div>
  );
};

export default Settings;
