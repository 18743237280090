import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { IVehicle, IService } from '../../store/vehicles/vehicles-models';
import { VehicleSelectors } from '../../store/vehicles/vehicle-selectors';
import ServiceCard, {
  EditServiceDialog,
} from '../../components/serviceCard/serviceCard';
import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';
import styles from './serviceList.module.css';
import { VehicleDetailsTable } from '../../components/vehicleDetailsTable/vehicleDetailsTable';
import { VehicleServicesTable } from '../../components/vehicleServicesTable/vehicleServicesTable';

interface IProps {
  vehicle: IVehicle;
  updateHistory: (v: string, s: IService) => void;
}

function getBlankService() {
  return {
    km: '',
    date: '',
    description: '',
    price: '',
    tags: [],
  };
}

function ServiceList(props: IProps) {
  const { vehicle, updateHistory } = props;
  const history = useSelector(VehicleSelectors.getHistory);
  const [open, setOpen] = useState(false);
  const [service, setService] = useState<IService>(getBlankService());
  const handleClose = () => {
    setOpen(false);
    setService(getBlankService());
  };

  return (
    <>
      <div className={styles.mobile}>
        <h1 className={styles.title}>Huollot</h1>
        <VehicleDetailsTable vehicle={vehicle} />
        {history && <VehicleServicesTable services={history} />}
      </div>
      <Fab
        className={styles.add}
        color="primary"
        aria-label="add"
        size={'small'}
        onClick={() => setOpen(true)}
      >
        <AddIcon />
      </Fab>
      <EditServiceDialog
        open={open}
        handleClose={handleClose}
        service={service}
        vehicleId={vehicle._id}
      />
      <div className={styles.services}>
        {history &&
          history.map((service: IService) => (
            <ServiceCard
              key={service._id}
              service={service}
              vehicleId={vehicle._id}
              updateHistory={updateHistory}
            />
          ))}
        {(!vehicle.history || vehicle.history.length === 0) && (
          <p>Ei huoltohistoriaa</p>
        )}
      </div>
    </>
  );
}

export default ServiceList;
