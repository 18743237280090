import { Paper } from '@material-ui/core';
import SpeedIcon from '@material-ui/icons/Speed';
import React, { FC } from 'react';
import { CarLogo } from '../../components/cards/Car';
import { Column, Container } from '../../components/grid/columns';
import LinkTankkaus from '../../components/LinkTankkaus';
import TankkausTable from '../../components/TankkausTable';
import { useSelectorTs } from '../../store';
import { TankkausSelectors } from '../../store/tankkaus/tankkaus-selectors';
import { VehicleSelectors } from '../../store/vehicles/vehicle-selectors';
import { formatNumber } from '../../util';
import styles from './stats.module.css';
import { TankkausType } from './tankkaus';

type TankkauksetStatsType = {
  averageConsumption: number;
  averagePricePerLitre: number;
  travelled: number;
};

function getStats(tankkaukset: TankkausType[]): TankkauksetStatsType {
  const average = (acc, curr: TankkausType) => acc + curr.price / curr.quantity;
  const averagePricePerLitre =
    tankkaukset.reduce(average, 0) / tankkaukset.length;

  const travelledReducer = (acc, curr: TankkausType) => {
    if (!acc.last) {
      return {
        last: curr,
        km: acc.km,
      };
    }
    return {
      last: curr,
      km: acc.km + curr.km - acc.last.km,
    };
  };
  const travelled = tankkaukset.reduce<{
    last: TankkausType | null;
    km: number;
  }>(travelledReducer, { last: null, km: 0 }).km;

  const averageConsumption =
    tankkaukset.reduce<Acc>(consumptionReducer, { consumption: 0 })
      .consumption /
    (tankkaukset.length - 1);
  return {
    averageConsumption,
    averagePricePerLitre,
    travelled,
  };
}

type Acc = {
  last?: TankkausType;
  consumption: number;
};
/*
 *  km       40  40 / 100 * 4
 *  kulutus  4
 *  l/100km = kulutus / (km / 100)
 */
const consumptionReducer = (acc: Acc, curr: TankkausType) => {
  if (acc?.last) {
    return {
      last: curr,
      consumption:
        acc.consumption + curr.quantity / ((curr.km - acc.last.km) / 100),
    };
  }
  return {
    last: curr,
    consumption: 0,
  };
};

const TankkauksetStats: FC = () => {
  const tankkaukset = useSelectorTs(TankkausSelectors.tankkaukset);
  const vehicle = useSelectorTs(VehicleSelectors.getVehicle);

  const { averagePricePerLitre, averageConsumption, travelled } = getStats(
    tankkaukset ?? []
  );

  if (!vehicle || !tankkaukset) {
    return null;
  }

  return (
    <Paper className={styles.container}>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr',
          marginBottom: '1rem',
          justifyItems: 'center',
          alignItems: 'center',
        }}
      >
        <CarLogo make={vehicle.make} />
        <LinkTankkaus add vehicleId={vehicle._id} />
      </div>

      <Container>
        <Column title={'Ajettu'}>
          <SpeedIcon /> {formatNumber(travelled)} km
        </Column>
        <Column title={'Polttoaineen keskihinta'}>
          {formatNumber(averagePricePerLitre)} €/litra
        </Column>
        <Column title={'Keskikulutus'}>
          {formatNumber(averageConsumption)} l/100km
        </Column>
      </Container>
      {tankkaukset && (
        <TankkausTable tankkaukset={tankkaukset} travelled={travelled} />
      )}
    </Paper>
  );
};

export default TankkauksetStats;
