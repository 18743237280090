import { Theme } from "@material-ui/core";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import { makeStyles } from "@material-ui/styles";
import React, { useState } from "react";
import { useDispatchTs } from "../store";
import { addVehicle } from "../store/vehicles/vehicles-actions";
import MakeSelect from "./inputs/MakeSelect";
import ModelSelect from "./inputs/ModelSelect";
import { useHistory } from "react-router";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "1rem",
  },
  ".container div": {
    marginRight: "1rem",
  },
  textField: {
    marginLeft: theme.spacing,
    marginRight: theme.spacing,
    width: 200,
  },
  dense: {
    marginTop: 19,
  },
  menu: {
    width: 200,
  },
  button: {
    margin: theme.spacing,
  },
  selectMake: {
    marginRight: "1rem",
    display: "none",
  },
}));

export interface IState {
  make: string;
  model: string;
}

const AddVehicleBasicsForm = () => {
  const history = useHistory();

  const [vehicle, setMake] = useState<{ make: string; model: string }>({
    make: "",
    model: "",
  });
  const dispatch = useDispatchTs();
  const classes = useStyles();
  const handleSave = async () => {
    const veh = Object.assign({}, vehicle);
    vehicle.model = "";
    vehicle.make = "";
    setMake(vehicle);
    const newVehicle = await dispatch(addVehicle(veh));
    history.push(`/vehicle/${newVehicle._id}/info`);
  };

  return (
    <form className={classes.container} noValidate autoComplete="off">
      <MakeSelect
        className={classes.selectMake}
        onChange={(make) => setMake({ ...vehicle, make })}
      />
      <ModelSelect
        makeName={vehicle.make}
        onChange={(model) => model !== null && setMake({ ...vehicle, model })}
      />
      <Fab onClick={handleSave} color="primary" aria-label="add" size={"small"}>
        <AddIcon />
      </Fab>
    </form>
  );
};

export default AddVehicleBasicsForm;
