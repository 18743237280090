import { Button } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/styles';
import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { useDispatchTs } from 'store';
import { login } from 'store/auth/auth-actions';
import { LoginCredentials } from 'store/auth/auth-models';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  form: {
    margin: '1em',
    border: '4px double #caccaa',
    borderRadius: '15px',
    padding: '1em',
    width: '50%',
    '@media (max-width:600px)': {
      width: '100%',
      padding: '0.5em',
      margin: '0.1em',
    },
  },
  title: {
    margin: 10,
  },
  error: {
    color: 'red',
  },
  input: {
    textDecoration: 'none',
    color: '#fff',
    margin: 10,
  },
  button: {
    margin: 10,
  },
  formControl: {
    width: '80%',
    '@media (max-width:600px)': {
      width: '100%',
    },
  },
});

export const LoginForm = () => {
  const history = useHistory();
  const classes = useStyles();
  const [user, setUser] = useState<LoginCredentials>({
    username: '',
    password: '',
  });
  const [error, setError] = useState<string | undefined>();
  const dispatch = useDispatchTs();
  const userLogin = async (e) => {
    e.preventDefault();
    const result = await dispatch(login(user));
    if (result !== undefined) {
      history.push('/');
      return <p>Menossa etusivulle...</p>;
    }
    setError('Error');
  };
  return (
    <div className={classes.container}>
      <form
        className={classes.form}
        autoComplete="on"
        onSubmit={(e) => userLogin(e)}
      >
        <h1 className={classes.title}>Kirjautuminen</h1>
        <FormControl className={classes.formControl}>
          <TextField
            className={classes.input}
            required
            label="Käyttäjätunnus"
            autoComplete="username"
            value={user.username}
            onChange={(e) => setUser({ ...user, username: e.target.value })}
            margin="normal"
            variant="outlined"
            error={!!error}
          />
          <TextField
            className={classes.input}
            required
            label="Salasana"
            autoComplete="current-password"
            type={'password'}
            value={user.password}
            onChange={(e) => setUser({ ...user, password: e.target.value })}
            margin="normal"
            variant="outlined"
            error={!!error}
            helperText={!!error ? 'Salasana väärin' : ''}
          />
          <Button
            className={classes.button}
            type="submit"
            variant="contained"
            color="primary"
          >
            Kirjaudu
          </Button>
        </FormControl>
      </form>
    </div>
  );
};
