import { TankkausType } from '../../pages/tankkaus/tankkaus';
import { createType } from '../../util';
import { apiRequest } from '../../utils/apiRequest';
import { Dispatch } from '../index';

const moduleName = 'tankkaus';

export const types = {
  set: createType(moduleName, 'set'),
};
export const TankkausActions = {
  set: (payload: TankkausType[]) => ({
    type: types.set,
    payload,
  }),
};
const apiPath = '/api/tankkaus';
export const get = (vehicleId: string) => async (
  dispatch: Dispatch
): Promise<TankkausType[] | undefined> => {
  const response = await apiRequest(`${apiPath}/${vehicleId}`);
  if (response.ok) {
    const result: TankkausType[] = await response.json();
    dispatch(TankkausActions.set(result));
    return result;
  }
  console.warn('Error getting tankkaukset', response);
};

export const add = (tankkaus: TankkausType) => async (dispatch: Dispatch) => {
  if (!tankkaus.refVehicle) {
    throw Error('missing ref');
  }
  await apiRequest(`${apiPath}/${tankkaus.refVehicle}`, {
    method: 'post',
    body: JSON.stringify(tankkaus),
  });
  dispatch(get(tankkaus.refVehicle));
};
