import { default as moment } from "moment";

export const formatDate = (date: string): string =>
  moment(date).format("DD.MM.YYYY");
export const formatDateTime = (date: string): string =>
  moment(date).format("DD.MM.YYYY HH:mm:ss");

export const createType = (module: string, action: string): string =>
  `APP::${module}/${action}`;

export const sortBy = (field: string) => (a: any, b: any) => {
  if (a[field] < b[field]) return -1;
  else if (a[field] > b[field]) return 1;
  return 0;
};

export const formatPrice = (num: number) =>
  new Intl.NumberFormat("fi-FI", {
    style: "currency",
    maximumFractionDigits: 2,
    currency: "EUR",
    currencyDisplay: "symbol",
  }).format(num);

export const formatNumber = (num: number, maximumSignificantDigits?: number) =>
  new Intl.NumberFormat("fi-FI", { maximumSignificantDigits }).format(num);

export const formatAmount = (amount: number, unit = "kpl") =>
  formatNumber(amount) + " " + unit;
