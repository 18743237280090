import { LinearProgress } from '@material-ui/core';
import TireSizes from 'pages/tireSizes/tireSizes';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { Redirect } from 'react-router';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import './App.css';
import { Alerts } from './components/Alerts';
import { Footer } from './components/Footer';
import { Landing } from './components/landing/landing';
import Menu from './components/Menu';
import ScrollToTop from './components/ScrollToTop';
import { CreateUser } from './components/user/CreateUser';
import { LoginPage } from './components/user/LoginPage';
import { LogoutPage } from './components/user/LogoutPage';
import { VehiclesList } from './components/VehiclePage';
import AtCalc from "./pages/atCalc";
import FuelCalc from './pages/fuelCalc/fuelCalc';
import { Manuals } from './pages/manuals/Manuals';
import Tankkaukset from './pages/tankkaus';
import VehicleView from './pages/vehicleView/VehicleView';
import { ApplicationState, useDispatchTs, useSelectorTs } from './store';
import { initialize } from './store/main/main-actions';

const App: FC = () => {
  const dispatch = useDispatchTs();
  const [loading, setLoading] = useState<boolean>(false);
  const initialized = useSelectorTs(
    (state: ApplicationState) => state.main.initialized
  );
  const loggedIn = useSelectorTs(
    (state: ApplicationState) => state.auth.loggedIn
  );

  const init = useCallback(async () => {
    setLoading(true);
    await dispatch(initialize());
    setLoading(false);
  }, [dispatch, setLoading]);

  useEffect(() => {
    if (!loggedIn && !initialized && !loading) {
      init();
    }
  }, [loading, loggedIn, init, initialized]);

  return (
    <Router>
      <ScrollToTop />
      <div className="App">
        <div className="Content">
          <Menu />
          {!initialized && <LinearProgress />}
          {initialized && (
            <Switch>
              <Route
                exact={true}
                path="/polttoainelaskuri"
                component={FuelCalc}
              />
              <Route exact={true} path="/at-laskuri" component={AtCalc} />
              <Route exact={true} path="/rengaskoot" component={TireSizes} />
              {loggedIn && (
                <>
                  <Route
                    path="/tankkaukset/:vehicleId"
                    component={Tankkaukset}
                  />
                  <Route exact={true} path="/" component={VehiclesList} />
                  <Route
                    path="/vehicle/:vehicleId/:tab"
                    component={VehicleView}
                  />
                  <Route path="/user/create" component={CreateUser} />
                  <Route path="/manuals" component={Manuals} />

                  <Route path="/logout" component={LogoutPage} />
                </>
              )}
              {!loggedIn && (
                <>
                  <Route path="/login" component={LoginPage} />
                  <Route exact={true} path="/" component={Landing} />
                  <Route path="/manuals" component={Manuals} />
                </>
              )}
              <Redirect to={'/'} />
            </Switch>
          )}
          <Alerts />
        </div>
        <Footer />
      </div>
    </Router>
  );
};

export default App;
