// abstract
export async function apiRequest(info: RequestInfo, init?: RequestInit) {
  const initOptions = {
    ...init,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json; charset=utf-8",
    },
  };
  return await fetch(info, initOptions);
}
