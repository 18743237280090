import { Paper, TextField } from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { Check, Error, Info } from "@material-ui/icons";
import moment from "moment";
import * as React from "react";
import { useSelector } from "react-redux";
import { VehicleSelectors } from "../../store/vehicles/vehicle-selectors";
import { IServiceJob, IVehicle } from "../../store/vehicles/vehicles-models";
import { formatDate, formatNumber } from "../../util";
import JobEditor from "../JobEditor";

const useStyles = makeStyles({
  card: {
    border: "1px solid gray",
    margin: "1em",
  },

  title: {
    backgroundColor: "lightgray",
    borderBottom: "1px solid gray",
  },

  error: {
    float: "right",
    marginRight: "2px",
    color: "red",
  },
  ok: {
    float: "right",
    marginRight: "2px",
    color: "green",
  },
  warn: {
    float: "right",
    marginRight: "2px",
    color: "blue",
  },

  paper: {
    padding: "0.5em 1em",
    marginBottom: "1em",
    display: "grid",
    gridTemplateColumns: "50% 50%",
    "@media (max-width:600px)": {
      gridTemplateColumns: "100%",
    },
    "@media (min-width:2000px)": {
      gridTemplateColumns: "25% 25% 25% 25%",
    },
  },
});

// TODO requires some love, code is pretty much just smashed together now
const StateIcon = (props: { vehicle: IVehicle; serviceJob: IServiceJob }) => {
  const classes = useStyles();
  const { vehicle, serviceJob } = props;
  const { job, service } = serviceJob;
  const intervals = {
    mileage: 0,
    age: 0,
  };

  // If no service done, use car mileage and age
  if (!service) {
    intervals.age = moment().diff(moment(vehicle.registrationDate), "years");
    intervals.mileage = vehicle.km;
  } else {
    intervals.mileage = vehicle.km - Number(service.km);
    intervals.age = moment().diff(moment(service.date), "years");
  }

  let state = 0;
  if (service === undefined) {
    state = 0;
  }
  if (job.mileage) {
    if (intervals.mileage >= job.mileage.replace) {
      state = 2;
    } else if (intervals.mileage >= job.mileage.warn) {
      state = 1;
    }
  }
  // TODO paranna tätä, ihan paska tämä algoritmi
  if (job.age) {
    if (state < 2 && intervals.age >= job.age.replace) {
      state = 2;
    } else if (state < 1 && intervals.age >= job.age.warn) {
      state = 1;
    }
  }

  switch (state) {
    case 1:
      return <Info className={classes.warn} />;
    case 2:
      return <Error className={classes.error} />;
    default:
      return <Check className={classes.ok} />;
  }
};

const filterByTitle = (search: string) => (serviceJob: IServiceJob) =>
  search === "" || serviceJob.job.title.search(new RegExp(search, "i")) > -1;
export const Services = () => {
  // TODO replace with one selector
  const styles = useStyles();
  const services = useSelector(VehicleSelectors.getLatestServices);
  const vehicle = useSelector(VehicleSelectors.getVehicle);
  const [filter, setFilter] = React.useState("");
  if (!vehicle) return <p>No vehicle, wut?</p>;

  return (
    <>
      <Paper className={styles.paper}>
        {services.length === 0 && (
          <p>Lisää pari huoltoa näkyviin, niin näyttää paremmalta.</p>
        )}
        <form className="container">
          <TextField
            label="Suodatus"
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
            margin="normal"
            fullWidth={true}
          />
        </form>
        {services.filter(filterByTitle(filter)).map((serviceJob, index) => (
          <OneService key={index} vehicle={vehicle} serviceJob={serviceJob} />
        ))}
      </Paper>
      <JobEditor />
    </>
  );
};
const getAgeObject = (date: string) => ({
  years: moment().diff(moment(date), "years"),
  months: moment().diff(moment(date), "months"),
  days: moment().diff(moment(date), "days"),
});
const getServiceAgeAsReadableString = (age) => {
  if (age.years === 0) {
    if (age.months === 0) {
      return `${age.days} ${age.days === 1 ? "päivä" : "päivää"}`;
    }
    return `${age.months} ${age.months === 1 ? "kuukausi" : "kuukautta"}`;
  }
  return `${age.years} ${age.years === 1 ? "vuosi" : "vuotta"}`;
};

export const OneService = (props: {
  vehicle: IVehicle;
  serviceJob: IServiceJob;
}) => {
  const classes = useStyles();
  const { serviceJob, vehicle } = props;
  const { service } = serviceJob;
  const intervals = service
    ? {
        mileage: vehicle.km - Number(service.km),
        age: getServiceAgeAsReadableString(getAgeObject(service.date)),
      }
    : {
        mileage: vehicle.km,
        age: getServiceAgeAsReadableString(
          getAgeObject(vehicle.registrationDate)
        ),
      };

  let mileage, date;
  if (!service) {
    mileage = 0;
    date = vehicle.registrationDate;
  } else {
    mileage = service.km;
    date = service.date;
  }

  return (
    <div className={classes.card}>
      <div className={classes.title}>
        {serviceJob.job.title}{" "}
        <StateIcon vehicle={vehicle} serviceJob={serviceJob} />
      </div>
      <div>
        {formatDate(date)} [{intervals.age} sitten] - {formatNumber(mileage)} km
        [{formatNumber(intervals.mileage)} km sitten]
      </div>
    </div>
  );
};
