import React, { useEffect, useState } from "react";
import { useDispatchTs } from "../../store";
import { getPublicVehicles } from "../../store/vehicles/vehicles-actions";
import { useSelector } from "react-redux";
import { VehicleSelectors } from "../../store/vehicles/vehicle-selectors";
import { makeStyles } from "@material-ui/core/styles";
import { IImage, IVehicle } from "../../store/vehicles/vehicles-models";
import { Backdrop } from "@material-ui/core";
import { DriveTrain, FuelTypes, GearType } from "../../fixtures/cars";
import { formatDate, formatDateTime, formatNumber } from "../../util";
import { getImgUrl } from "../../utils/vehicle";
import { ImageViewer } from "../cards/ImageViewer";

export function Landing() {
  const dispatch = useDispatchTs();
  const publicVehicles = useSelector(VehicleSelectors.getPublicVehicleList);
  useEffect(() => {
    dispatch(getPublicVehicles());
  }, [dispatch]);

  if (!publicVehicles) {
    return <p>Lataa</p>;
  }

  return (
    <div
      style={{
        display: "flex",
        flexFlow: "row",
        justifyContent: "center",
        flexWrap: "wrap",
      }}
    >
      {publicVehicles.map((vehicle: Partial<IVehicle>) => (
        <VehicleBox key={vehicle._id} vehicle={vehicle} />
      ))}
    </div>
  );
}
const useStyles = makeStyles((theme) => ({
  box: {
    position: "relative",
    backgroundColor: "rgba(175,175,175,0.48)",
    margin: "0.5em",
    padding: "2em",
    textAlign: "left",
    borderRadius: "5px",
  },
  title: {
    borderBottom: "1px solid gray",
    marginBottom: "0.4em",
  },
  row: {},
  updated: {
    position: "absolute",
    bottom: "1em",
    marginTop: "0.7em",
    fontStyle: "italic",
    alignSelf: "flex-end",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  image: {
    cursor: "pointer",
  },
}));

const getImage = ({ make, model, images }) => {
  if (images.length > 0) {
    return (
      <img
        width="200"
        alt={`${make} - ${model}`}
        src={getImgUrl(images[0].url)}
      />
    );
  }
  return null;
};

const VehicleBox = ({ vehicle }) => {
  const classes = useStyles();
  const [visibleImage, setVisibleImage] = useState<IImage>();

  // TODO move to hook
  const showImage = (image) => (e) => {
    setVisibleImage(image);
  };

  const getPrevious = () => {
    if (vehicle && vehicle.images.length > 0) {
      const pos = !!visibleImage
        ? vehicle.images.findIndex((img) => visibleImage._id === img._id) - 1
        : 0;
      return vehicle.images[pos];
    }
    return visibleImage;
  };
  const getNext = () => {
    if (vehicle && vehicle.images.length > 0) {
      const pos = !!visibleImage
        ? vehicle.images.findIndex((img) => visibleImage._id === img._id) + 1
        : 0;
      return vehicle.images[pos];
    }
    return visibleImage;
  };
  const handleClose = () => setVisibleImage(undefined);
  const image = getImage(vehicle);
  return (
    <>
      <div className={classes.box}>
        <div
          className={classes.title}
        >{`${vehicle.make} ${vehicle.model}`}</div>
        {vehicle.registrationDate && (
          <div className={classes.row}>{`Rekisteröity: ${formatDate(
            vehicle.registrationDate
          )}`}</div>
        )}
        {vehicle.km && (
          <div className={classes.row}>{`Ajettu: ${formatNumber(
            vehicle.km
          )} km`}</div>
        )}
        {vehicle.fuelType && (
          <div className={classes.row}>{`Käyttövoima: ${
            FuelTypes[vehicle.fuelType].name
          }`}</div>
        )}
        {vehicle.driveTrain && (
          <div className={classes.row}>{`Vetotapa: ${
            DriveTrain[vehicle.driveTrain].name
          }`}</div>
        )}
        {vehicle.gearbox && (
          <div className={classes.row}>{`Vaihteisto: ${
            GearType[vehicle.gearbox].name
          }`}</div>
        )}
        {image && (
          <div className={classes.image} onClick={showImage(vehicle.images[0])}>
            {image}
          </div>
        )}
        {vehicle.updatedAt && (
          <div className={classes.updated}>
            Päivitetty {`${formatDateTime(vehicle.updatedAt)}`}
          </div>
        )}
      </div>
      <Backdrop className={classes.backdrop} open={!!visibleImage}>
        {visibleImage && (
          <ImageViewer
            previous={getPrevious()}
            next={getNext()}
            current={visibleImage}
            show={(img) => setVisibleImage(img)}
            close={handleClose}
          />
        )}
      </Backdrop>
    </>
  );
};
