import React, { FC } from "react";
import { Expenses } from "../../components/expenses/Expenses";

const Statistics: FC = () => {
  return (
    <div>
      <h2>Tilastoja</h2>
      <section>
        <Expenses />
      </section>
      <section>
        <h3>TODO auton statistiikkoja</h3>
      </section>
    </div>
  );
};

export default Statistics;
