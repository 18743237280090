import { Grid } from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';
import React, { useEffect } from 'react';
import { useDispatchTs, useSelectorTs } from '../store';
import { VehicleSelectors } from '../store/vehicles/vehicle-selectors';
import { getVehicles, unsetVehicle } from '../store/vehicles/vehicles-actions';
import { IVehicle } from '../store/vehicles/vehicles-models';
import AddVehicleBasicsForm from './AddVehicleBasicsForm';
import Car from './cards/Car';
import styles from './VehiclePage.module.css';

export const VehiclesList = () => {
  const dispatch = useDispatchTs();
  const vehicles = useSelectorTs(VehicleSelectors.getVehicleList);

  useEffect(() => {
    dispatch(unsetVehicle());
    dispatch(getVehicles()).catch((err) =>
      alert('Ajoneuvojen haku epäonnistui')
    );
  }, [dispatch]);

  return (
    <Grid container direction="row" justify="center" alignItems="center">
      <Grid item xs={12}>
        <AddVehicleBasicsForm />
      </Grid>
      <Grid item xs={12} md={8} className={styles.vehicleRow}>
        {!vehicles && <LinearProgress />}
        {vehicles && (
          <>
            {vehicles.map((vehicle: IVehicle) => (
              <Car key={vehicle._id} vehicle={vehicle} />
            ))}
          </>
        )}
      </Grid>
    </Grid>
  );
};
