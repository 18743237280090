import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { Action, AnyAction, combineReducers } from 'redux';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { AppState } from './app/app-models';
import { app } from './app/app-reducer';
import { AuthState } from './auth/auth-models';
import { auth } from './auth/auth-reducer';
import { ImageState } from './image/image-models';
import { image } from './image/image-reducer';
import { MainState } from './main/main-models';
import { main } from './main/main-reducer';
import { TankkausState } from './tankkaus/tankkaus-models';
import { tankkaus } from './tankkaus/tankkaus-reducer';
import { IVehiclesState } from './vehicles/vehicles-models';
import { vehicles } from './vehicles/vehicles-reducer';

const rootReducer = () =>
  combineReducers({
    image,
    app,
    main,
    vehicles,
    auth,
    tankkaus,
  });

export type ApplicationState = {
  image: ImageState;
  app: AppState;
  main: MainState;
  vehicles: IVehiclesState;
  auth: AuthState;
  tankkaus: TankkausState;
};

export type Dispatch = ThunkDispatch<ApplicationState, undefined, AnyAction>;
export type GetState = () => ApplicationState;

export type AsyncAction<R = void> = ThunkAction<
  Promise<R>,
  ApplicationState,
  undefined,
  AnyAction
>;
export type DispatchAction<T extends AnyAction = Action> = ThunkDispatch<
  ApplicationState,
  undefined,
  T
>;
export const useSelectorTs: TypedUseSelectorHook<ApplicationState> = useSelector;
export const useDispatchTs: () => DispatchAction = useDispatch;

export default rootReducer;
