import React, {useState} from 'react';
import {At, AtCalcForm} from "./atCalcForm";
import styles from './atCalc.module.css';
import {AtCalcResults} from "./atCalcResults";


const AtCalc = () => {
  const [startTime, setStartTime] = useState<string>('');
  const [driveTime, setDriveTime] = useState<string>('');
  const [atTimes, setAtTimes] = useState<At[]>([]);
  return (
      <div className={styles.container}>
        <div className={styles.form}>
          <AtCalcForm atTimes={atTimes} setAtTimes={setAtTimes} setStartTime={setStartTime} startTime={startTime} setDriveTime={setDriveTime} driveTime={driveTime}/>
        </div>
        <div className={styles.result}>
          <AtCalcResults startTime={startTime} atTimes={atTimes} />
        </div>
      </div>
  );
}



export default AtCalc;
