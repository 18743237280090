import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { createStyles } from "@material-ui/styles";
import * as React from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import MarkdownView from "react-showdown";
import { deleteManual, updateManual } from "../store/vehicles/vehicles-actions";
import { AddManual } from "./AddManual";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import Fab from "@material-ui/core/Fab";
import { IManual } from "../store/manuals/manuals-models";
import YesNoDialog from "./dialogs/YesNoDialog";
import { AntSwitch } from "./buttons/AntSwitch";

const useStyles = makeStyles(() =>
  createStyles({
    card: {
      minWidth: 275,
      margin: "1em",
    },
    bullet: {
      display: "inline-block",
      margin: "0 2px",
      transform: "scale(0.8)",
    },
    title: {
      fontSize: 14,
    },
    pos: {
      marginBottom: 12,
    },
    buttons: {
      marginBottom: "1em",
    },
    doc: {
      backgroundColor: "#e6e9ec",
      padding: "1em",
    },
    heading: {},
    details: {
      width: "100%",
      textAlign: "left",
    },
    deleteIcon: {
      marginLeft: "1rem",
    },
    publicToggle: {
      float: "right",
      display: "inline-flex",
      margin: "1rem",
    },
  })
);

interface IManualCardProps {
  vehicleId: string;
  manual: IManual;
  update: (v: string, s: IManual) => void;
}

type Props = IManualCardProps;

function ManualPanel(props: Props) {
  const { manual, vehicleId } = props;
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [askDialogOpen, setAskDialogOpen] = useState(false);
  const dispatch = useDispatch();
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  const handleDelete = () => dispatch(deleteManual(vehicleId, manual));
  const handlePublic = () =>
    dispatch(updateManual(vehicleId, { ...manual, public: !manual.public }));
  const onSave = (manual) => dispatch(updateManual(vehicleId, manual));

  return (
    <>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>{manual.name}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div className={classes.details}>
            <div className={classes.buttons}>
              <Fab
                size="small"
                color="primary"
                aria-label="edit"
                onClick={handleOpen}
              >
                <EditIcon />
              </Fab>
              <Fab
                size="small"
                className={classes.deleteIcon}
                color="secondary"
                aria-label="delete"
                onClick={() => setAskDialogOpen(true)}
              >
                <DeleteIcon />
              </Fab>
              <Typography component="div" className={classes.publicToggle}>
                <Grid
                  component="label"
                  container
                  alignItems="center"
                  spacing={1}
                >
                  <Grid item>Yksityinen</Grid>
                  <Grid item>
                    <AntSwitch
                      checked={manual.public}
                      onChange={handlePublic}
                      name="public"
                    />
                  </Grid>
                  <Grid item>Julkinen</Grid>
                </Grid>
              </Typography>
            </div>
            <div className={classes.doc}>
              <MarkdownView markdown={manual.doc as string} />
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
      <EditManualDialog
        open={open}
        handleClose={handleClose}
        manual={manual}
        onSave={onSave}
      />
      <YesNoDialog
        open={askDialogOpen}
        onYes={handleDelete}
        onNo={() => setAskDialogOpen(false)}
      >
        Oletko nyt aivan varma, että haluat poistaa kyseisen manuaalin?
      </YesNoDialog>
    </>
  );
}

interface IManualDialogProps {
  manual: IManual;
  handleClose: (e?: any) => void;
  onSave: Function;
  open: boolean;
}

export function EditManualDialog(props: IManualDialogProps) {
  const { handleClose, open, onSave } = props;
  const [manual, setManual] = useState({ ...props.manual });

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      fullWidth={true}
    >
      <DialogTitle id="form-dialog-title">Manuaalin editointi</DialogTitle>
      <DialogContent>
        <AddManual manual={manual} onChange={setManual} />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setManual(manual);
            handleClose();
          }}
          color="secondary"
        >
          Peruuta
        </Button>
        <Button
          onClick={() => {
            onSave(manual);
            handleClose();
          }}
          color="primary"
        >
          Tallenna
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ManualPanel;
