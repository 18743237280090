export const Types = {
  touring: {
    name: "Farmari",
  },
  coupe: {
    name: "Coupe",
  },
  sedan: {
    name: "Sedan",
  },
  hatchback: {
    name: "Hatchback",
  },
} as const;

export const FuelTypes = {
  petrol: {
    name: "Bensiini",
  },
  diesel: {
    name: "Diesel",
  },
  electric: {
    name: "Sähkö",
  },
  gas: {
    name: "Kaasu",
  },
  petrolAndElectric: {
    name: "Hybridi (bensiini ja sähkö)",
  },
  dieselAndElectric: {
    name: "Hybridi (diesel ja sähkö)",
  },
};

export const DriveTrain = {
  awd: {
    name: "Neliveto",
  },
  fwd: {
    name: "Etuveto",
  },
  rwd: {
    name: "Takaveto",
  },
};

export const GearType = {
  automatic: {
    name: "Automaatti",
  },
  manual: {
    name: "Manuaali",
  },
} as const;

export const Cars = {
  bmw: {
    name: "BMW",
    models: {
      e46Coupe330: {
        type: Types.coupe,
        fuel: FuelTypes.petrol,
        model: "e46",
        variant: "330Ci",
      },
      e46Sedan330: {
        type: Types.sedan,
        fuel: FuelTypes.petrol,
        model: "e46",
        variant: "330i",
      },
      e46Touring330: {
        type: Types.touring,
        fuel: FuelTypes.petrol,
        model: "e46",
        variant: "330i",
      },
      e46Compact330: {
        type: Types.hatchback,
        fuel: FuelTypes.petrol,
        model: "e46",
        variant: "330i",
      },
      e90330xd: {
        variant: "330xd",
        fuel: FuelTypes.diesel,
        model: "e90",
        type: Types.sedan,
      },
      e91Touring330xd: {
        variant: "330xd",
        model: "e91",
        fuel: FuelTypes.diesel,
        type: Types.touring,
      },
      f10Sedan523: {
        variant: "523i",
        model: "F10",
        fuel: FuelTypes.petrol,
        type: Types.sedan,
      },
    },
  },
  skoda: {
    name: "Skoda",
    models: {
      fabia12TsiMk3Hatchback: {
        variant: "",
      },
    },
  },
} as const;
