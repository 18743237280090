import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormLabel from "@material-ui/core/FormLabel";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import ExpandMoreIcon from "@material-ui/core/SvgIcon/SvgIcon";
import Typography from "@material-ui/core/Typography";
import SettingsIcon from "@material-ui/icons/Settings";
import React from "react";
import { IJobs, Jobs } from "../fixtures/jobs";
import { useDispatchTs, useSelectorTs } from "../store";
import { VehicleSelectors } from "../store/vehicles/vehicle-selectors";
import { patchCurrentVehicle } from "../store/vehicles/vehicles-actions";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "block",
    },
    heading: {
      display: "flex",
      alignItems: "center",
    },
    formControl: {
      margin: theme.spacing(3),
      "@media (max-width:600px)": {
        margin: 0,
      },
    },
    buttons: {},
    formGroup: {
      width: "100%",
      margin: theme.spacing(3),
      display: "grid",
      gridTemplateColumns: "25% 25% 25% 25%",
      "@media (max-width:1024px)": {
        gridTemplateColumns: "33% 33% 33%",
      },
      "@media (max-width:800px)": {
        gridTemplateColumns: "50% 50%",
      },
      "@media (max-width:600px)": {
        gridTemplateColumns: "100%",
      },
    },
  })
);

const compareTitle = (a: keyof IJobs, b: keyof IJobs) =>
  Jobs[a].title.localeCompare(Jobs[b].title, "fi");

export default function JobEditor() {
  const classes = useStyles();
  const vehicle = useSelectorTs(VehicleSelectors.getVehicle);

  const serviceTagsSelected = {};
  if (vehicle) {
    vehicle.serviceTags.forEach((job) => (serviceTagsSelected[job] = true));
  }
  const [selectedJobs, setSelectedJobs] = React.useState(serviceTagsSelected);
  const dispatch = useDispatchTs();
  const handleChange = (name: string) => async () => {
    const serviceTags = {
      ...selectedJobs,
      [name]: selectedJobs[name] === undefined ? true : !selectedJobs[name],
    };
    await dispatch(
      patchCurrentVehicle({
        serviceTags: Object.keys(serviceTags).filter((job) => serviceTags[job]),
      })
    );
    setSelectedJobs(serviceTags);
  };
  const select = (state: boolean) => async () => {
    const selections = { ...selectedJobs };
    Object.keys(Jobs).forEach((key) => (selections[key] = state));
    await dispatch(
      patchCurrentVehicle({
        serviceTags: Object.keys(selections).filter((job) => selections[job]),
      })
    );
    setSelectedJobs(selections);
  };

  return (
    <div className={classes.root}>
      <Accordion defaultExpanded={Object.keys(selectedJobs).length === 0}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>
            <SettingsIcon /> Huoltojen asetukset
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <FormControl component="fieldset" className={classes.formControl}>
            <div className={classes.buttons}>
              <Button onClick={select(true)}>Valitse kaikki</Button>
              <Button onClick={select(false)}>Poista valinnat</Button>
            </div>
            <FormLabel component="legend">Näkyvissä olevat huollot</FormLabel>
            <FormGroup className={classes.formGroup}>
              {Object.keys(Jobs)
                .sort(compareTitle)
                .map((jobKey) => (
                  <FormControlLabel
                    key={jobKey}
                    control={
                      <Checkbox
                        checked={!!selectedJobs[jobKey]}
                        onChange={handleChange(jobKey)}
                        value={true}
                      />
                    }
                    label={Jobs[jobKey].title}
                  />
                ))}
            </FormGroup>
            <FormHelperText>Be careful</FormHelperText>
          </FormControl>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
