import { Grid, InputLabel } from '@material-ui/core';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import { Theme, withStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import moment from 'moment';
import * as React from 'react';
import { useState } from 'react';
import { connect } from 'react-redux';
import { DriveTrain, FuelTypes, GearType } from '../fixtures/cars';
import { Dispatch } from '../store';
import { patchCurrentVehicle } from '../store/vehicles/vehicles-actions';
import { IVehicle } from '../store/vehicles/vehicles-models';
import { CarLogo } from './cards/Car';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const styles = (theme: Theme) => ({
  root: {
    display: 'flex',
    marginTop: theme.spacing() * 3,
    'overflow-x': 'auto',
  },
  table: {
    minWidth: 700,
  },
  row: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },
  description: {
    flexGrow: 1,
    padding: '1em',
  },
  brief: {
    margin: '2em',
    '@media (max-width:600px)': {
      margin: '0.5em',
      textAlign: 'left',
    },
  },
  logo: {
    margin: '1em',
    '@media (max-width:600px)': {
      display: 'none',
    },
  },
  textField: {
    '@media (max-width:600px)': {
      width: '100%',
      margin: '0.5em',
    },
  },
  selectRow: {
    padding: '5px',
  },
});

const mapToDispatch = (dispatch: Dispatch) => ({
  patchVehicle: async (vehicleId: string, patch: Partial<IVehicle>) =>
    dispatch(patchCurrentVehicle(patch)),
});

type Props = { classes: ClassNameMap; vehicle: IVehicle } & ReturnType<
  typeof mapToDispatch
>;

function DetailsTable(props: Props) {
  const { classes, vehicle, patchVehicle } = props;
  const [veh, setVeh] = useState<IVehicle>(vehicle);

  const getValue = (event: any) => {
    if (
      event.target.getAttribute &&
      event.target.getAttribute('type') === 'checkbox'
    ) {
      return event.target.checked;
    } else {
      return event.target.value;
    }
  };

  // TODO don't save if nothing changed
  const handleSave = (field: string) => async (event: any) =>
    await patchVehicle(vehicle._id, { [field]: getValue(event) });

  const handleSelect = (name: string) => async (event: any) => {
    const newState = { ...veh };
    newState[name] = getValue(event);
    await handleSave(name)(event);
    setVeh(newState);
  };

  const handleChange = (field: string) => (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setVeh({ ...veh, [field]: event.target.value });
  };

  return (
    <Paper className={classes.root}>
      <Grid className={classes.logo}>
        <CarLogo make={vehicle.make} />
      </Grid>
      <Grid container className={classes.brief}>
        <Grid item xs={12} md={2}>
          <TextField
            label="Merkki"
            className={classes.textField}
            value={veh.make || ''}
            onChange={handleChange('make')}
            onBlur={handleSave('make')}
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <TextField
            label="Malli"
            className={classes.textField}
            value={veh.model || ''}
            onChange={handleChange('model')}
            onBlur={handleSave('model')}
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <TextField
            id="new-registrationDate"
            label="Rekisteröintipäivämäärä"
            type={'date'}
            className={classes.textField}
            value={
              vehicle.registrationDate &&
              moment(vehicle.registrationDate).format('YYYY-MM-DD')
            }
            onChange={handleSave('registrationDate')}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            id="vehicle-vin"
            label="Vin"
            className={classes.textField}
            value={veh.vin || ''}
            onChange={handleChange('vin')}
            onBlur={handleSave('vin')}
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <TextField
            id="vehicle-registrationNumber"
            label="Rekisterinumero"
            className={classes.textField}
            value={veh.registrationNumber || ''}
            onChange={handleChange('registrationNumber')}
            onBlur={handleSave('registrationNumber')}
          />
        </Grid>

        <Grid item xs={12} md={2}>
          <TextField
            inputProps={{ step: 1000, min: 0 }}
            id="vehicle-km"
            label="Km"
            type={'number'}
            className={classes.textField}
            value={veh.km || ''}
            onChange={handleChange('km')}
            onBlur={handleSave('km')}
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <TextField
            id="vehicle-purchase-date"
            label="Hankinta pvm."
            type={'date'}
            className={classes.textField}
            value={
              vehicle.purchaseDate &&
              moment(vehicle.purchaseDate).format('YYYY-MM-DD')
            }
            onChange={handleSave('purchaseDate')}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>

        <Grid item xs={12} md={2}>
          <TextField
            id="vehicle-purchase-price"
            label="Hankintahinta"
            type={'number'}
            className={classes.textField}
            value={vehicle.purchasePrice}
            onChange={handleSave('purchasePrice')}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>

        <Grid item xs={12} md={2}>
          <TextField
            id="vehicle-selling-price"
            label="Myyntihinta-arvio"
            type={'number'}
            className={classes.textField}
            value={vehicle.sellingPriceEstimate}
            onChange={handleSave('sellingPriceEstimate')}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>

        <Grid item xs={12} md={2}>
          <TextField
            inputProps={{ step: 1000, min: 0 }}
            id="vehicle-purchase-mileage"
            label="Hankinta km"
            type={'number'}
            className={classes.textField}
            value={veh.purchaseMileage || ''}
            onChange={handleChange('purchaseMileage')}
            onBlur={handleSave('purchaseMileage')}
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <TextField
            inputProps={{ step: 1000, min: 0 }}
            id="vehicle-insurance-cost"
            label="Vakuutusmaksu"
            type={'number'}
            className={classes.textField}
            value={veh.insuranceCost || ''}
            onChange={handleChange('insuranceCost')}
            onBlur={handleSave('insuranceCost')}
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <TextField
            inputProps={{ step: 1000, min: 0 }}
            id="vehicle-tax-cost"
            label="Vero"
            type={'number'}
            className={classes.textField}
            value={veh.taxCost || ''}
            onChange={handleChange('taxCost')}
            onBlur={handleSave('taxCost')}
          />
        </Grid>
        <Grid item xs={12} md={2} className={classes.selectRow}>
          <InputLabel htmlFor="fuel-type">Käyttövoima</InputLabel>
          <Select
            className={`${classes.textField}`}
            value={veh.fuelType || ''}
            onChange={handleSelect('fuelType')}
            input={<Input />}
            MenuProps={MenuProps}
          >
            {Object.keys(FuelTypes).map((name) => (
              <MenuItem key={name} value={name}>
                {FuelTypes[name].name}
              </MenuItem>
            ))}
          </Select>
        </Grid>

        <Grid item xs={12} md={2}>
          <TextField
            inputProps={{ min: 0 }}
            id="vehicle-average-fuel-consumption"
            label="Keskikulutus"
            type={'number'}
            className={classes.textField}
            value={veh.averageFuelConsumption || ''}
            onChange={handleChange('averageFuelConsumption')}
            onBlur={handleSave('averageFuelConsumption')}
          />
        </Grid>

        <Grid item xs={12} md={2} className={classes.selectRow}>
          <InputLabel htmlFor="gearbox">Vaihteisto</InputLabel>
          <Select
            className={classes.textField}
            value={veh.gearbox || ''}
            onChange={handleSelect('gearbox')}
            input={<Input />}
            MenuProps={MenuProps}
          >
            {Object.keys(GearType).map((name) => (
              <MenuItem key={name} value={name}>
                {GearType[name].name}
              </MenuItem>
            ))}
          </Select>
        </Grid>

        <Grid item xs={12} md={2} className={classes.selectRow}>
          <InputLabel htmlFor="drivetrain-type">Vetotyyppi</InputLabel>
          <Select
            className={classes.textField}
            value={veh.driveTrain || ''}
            onChange={handleSelect('driveTrain')}
            input={<Input />}
            MenuProps={MenuProps}
          >
            {Object.keys(DriveTrain).map((name) => (
              <MenuItem key={name} value={name}>
                {DriveTrain[name].name}
              </MenuItem>
            ))}
          </Select>
        </Grid>

        <Grid item xs={12} md={2} className={classes.selectRow}>
          <InputLabel htmlFor="public">Etusivulla</InputLabel>
          <Switch
            id="public"
            checked={veh.public}
            onChange={handleSelect('public')}
            color="primary"
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />
        </Grid>

        {vehicle.description && (
          <Grid item>
            <div className={classes.description}>{vehicle.description}</div>
          </Grid>
        )}
      </Grid>
    </Paper>
  );
}

export default withStyles(styles)(
  connect(undefined, mapToDispatch)(DetailsTable)
);
