import moment from "moment";
import React, { FC } from 'react';
import {At} from "./atCalcForm";


type FormProps = {
  startTime: string;
  atTimes: At[];
};

const getDate = (time: string) => {
  const date = moment();
  const parts = time.split(':');
  date.hours(parseInt(parts[0]))
  date.minutes(parseInt(parts[1]));
  return date;
}
const addTime = ({currentTime, duration}: {currentTime: string, duration: string}) => {
  const startTime = getDate(currentTime);
  const atTime = getDate(duration);
  return moment(startTime)
  .add(atTime.hours(), 'hours')
  .add(atTime.minutes(), 'minutes')
  .format('HH:mm');
}

const sumTimes = (startTime: string, times: At[]) =>
  times.reduce<string>((acc, time) =>
    addTime({currentTime: getDate(acc).format('HH:mm'), duration: time.duration}),
      startTime
  );


const AtCalcResults: FC<FormProps> = ({atTimes, startTime}: FormProps) => {
  return (
    <div>
      <h2>Lähtöaika {startTime}</h2>
      {atTimes.map((at, index) => (
        <AtCalculatedRow key={at.name} at={at} startTime={sumTimes(startTime, Array.from(atTimes).splice(0, index+1))} />
      ))}
    </div>
  );
}


const AtCalculatedRow = ({at, startTime}: {at: At, startTime: string}) => {

  return (
      <div>{at.name} {startTime}</div>
  )
}

export {AtCalcResults};
