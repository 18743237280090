import React from "react";
import { Theme, withStyles, WithStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { Button, InputLabel } from "@material-ui/core";
import { IPart } from "../store/vehicles/vehicles-models";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/es/Select";

const styles = (theme: Theme) => ({
  container: {
    display: "flex",
  },
  textField: {
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(),
    width: 200,
  },
  descriptionField: {
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(),
    width: 500,
  },
  dense: {
    marginTop: 19,
  },
  menu: {
    width: 200,
  },
  button: {
    margin: theme.spacing(),
  },
});

type HandlePartChange = Omit<IPart, "created">;
export interface Props extends WithStyles<typeof styles> {
  onAddVehiclePart?: (Part) => void;
  handleChange?: (part: HandlePartChange) => void;
  part?: IPart;
}

export interface State {
  _id?: string;
  name: string;
  refNumber: string;
  description: string;
  changeDate: string;
  changeKm: number;
  price: number;
  tags: string[];
  retired: boolean;
}
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const allCategories = [
  "Moottori",
  "Kori",
  "Etuakseli",
  "Sisusta",
  "Taka-akseli",
  "Voimansiirto",
];

class EditVehiclePartForm extends React.Component<Props, State> {
  state: State = {
    name: "",
    refNumber: "",
    changeDate: "",
    changeKm: 0,
    description: "",
    price: 0,
    tags: [],
    retired: false,
  };

  componentDidMount(): void {
    if (this.props.part) {
      this.setState({ ...this.props.part });
    }
  }

  handleChange = (name: keyof State) => (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const state: State = { ...this.state };
    state[name as string] = event.target.value;
    this.setState(state);
    if (this.props.handleChange) {
      this.props.handleChange(state);
    }
  };

  handleTagChange = (event: any) => {
    const tags = event.target.value;
    this.setState({ ...this.state, tags });
  };

  render() {
    const { classes, onAddVehiclePart } = this.props;

    return (
      <form className={classes.container} noValidate>
        <TextField
          id="new-part-name"
          label="Nimi"
          className={classes.textField}
          value={this.state.name}
          onChange={this.handleChange("name")}
          margin="normal"
        />

        <TextField
          id="new-part-ref"
          label="OEN"
          className={classes.textField}
          value={this.state.refNumber}
          onChange={this.handleChange("refNumber")}
          margin="normal"
        />
        <TextField
          id="new-part-km"
          label="Km"
          type={"number"}
          className={classes.textField}
          value={this.state.changeKm}
          onChange={this.handleChange("changeKm")}
          margin="normal"
        />

        <TextField
          id="new-part-change-date"
          label="Date"
          type={"date"}
          className={classes.textField}
          value={this.state.changeDate}
          onChange={this.handleChange("changeDate")}
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
        />

        <TextField
          id="new-part-description"
          label="Description"
          type={"textarea"}
          className={classes.descriptionField}
          value={this.state.description}
          onChange={this.handleChange("description")}
          margin="normal"
        />

        <TextField
          id="new-part-price"
          label="Price"
          type={"number"}
          className={classes.textField}
          value={this.state.price}
          onChange={this.handleChange("price")}
          margin="normal"
        />

        <InputLabel htmlFor="select-multiple">Kategoriat</InputLabel>
        <Select
          multiple
          value={this.state.tags}
          onChange={this.handleTagChange}
          input={<Input id="select-multiple" />}
          MenuProps={MenuProps}
        >
          {allCategories.map((name) => (
            <MenuItem key={name} value={name}>
              {name}
            </MenuItem>
          ))}
        </Select>

        {onAddVehiclePart && (
          <Button
            onClick={() => {
              onAddVehiclePart(this.state);
              // joku resetointi tähä
            }}
            variant="contained"
            color="primary"
            className={classes.button}
          >
            Add
          </Button>
        )}
      </form>
    );
  }
}

export default withStyles(styles)(EditVehiclePartForm);
