import React, { FC } from 'react';
import {IVehicle} from "../../store/vehicles/vehicles-models";
import styles from './vehicleStatusHistory.module.css';

const VehicleStatusHistory:FC<{ vehicle: IVehicle }> = ({ vehicle }) => {
return (
  <div className={styles.container}>
    <h3>Ajoneuvon tilahistoria</h3>
      <pre>TODO: Lisää/päivitä/poista historia, vaikutus veroon/vakuutukseen</pre>
      
      <pre>{JSON.stringify(vehicle.statusHistory, null, 2)}</pre>
  </div>
    );
}

export default VehicleStatusHistory;