import React, { useEffect, useState } from "react";
import { useDispatchTs, useSelectorTs } from "../../store";
import { ImageActions } from "../../store/image/image-actions";
import { VehicleSelectors } from "../../store/vehicles/vehicle-selectors";
import { Backdrop } from "@material-ui/core";
import { IImage } from "../../store/vehicles/vehicles-models";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { ImageViewer } from "../../components/cards/ImageViewer";
import YesNoDialog from "../../components/dialogs/YesNoDialog";
import { deleteImage } from "../../store/vehicles/vehicles-actions";
import ImageEditCard from "../../components/cards/ImageEditCard";

type FileEventTarget = EventTarget & { files: FileList };

const useStyles = makeStyles((theme: Theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  images: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
  },
  image: {
    cursor: "pointer",
    margin: "1rem",
  },
  deleteIcon: {
    cursor: "pointer",
  },
}));

export const VehicleImages = () => {
  const classes = useStyles();
  const vehicle = useSelectorTs(VehicleSelectors.getVehicle);
  const fileRef = React.useRef<HTMLInputElement>(null);
  const [files, setFiles] = useState<FileList>();
  const dispatch = useDispatchTs();
  useEffect(() => {
    if (!fileRef.current) return;
    fileRef.current.addEventListener("change", (e: Event) => {
      const target = e.target as FileEventTarget;
      setFiles(target.files);
    });
  }, [fileRef]);

  const [visibleImage, setVisibleImage] = useState<IImage>();
  const [askDialogOpen, setAskDialogOpen] = useState<IImage>();

  const sendImages = (e) => {
    e.preventDefault();
    if (files === null || files === undefined || !vehicle) {
      alert("no files!");
      return;
    }
    dispatch(ImageActions.sendImages(vehicle._id, files));
    fileRef!.current!.value = "";
  };

  const showImage = (image) => () => {
    setVisibleImage(image);
  };

  const getPrevious = () => {
    if (vehicle && vehicle.images.length > 0) {
      const pos = !!visibleImage
        ? vehicle.images.findIndex((img) => visibleImage._id === img._id) - 1
        : 0;
      return vehicle.images[pos];
    }
    return visibleImage;
  };
  const getNext = () => {
    if (vehicle && vehicle.images.length > 0) {
      const pos = !!visibleImage
        ? vehicle.images.findIndex((img) => visibleImage._id === img._id) + 1
        : 0;
      return vehicle.images[pos];
    }
    return visibleImage;
  };
  const handleClose = () => setVisibleImage(undefined);
  const handleDelete = () => {
    if (vehicle && !!askDialogOpen) {
      dispatch(deleteImage(vehicle._id!, askDialogOpen)).catch((e) =>
        alert("Kuvan poisto ei onnistunut")
      );
    }
    setAskDialogOpen(undefined);
  };

  return (
    <section>
      <form onSubmit={sendImages}>
        <input type="file" name="files" multiple ref={fileRef} />
        <button type="submit">Lähetä</button>
      </form>
      <div className={classes.images}>
        {vehicle?.images.map((image) => (
          <ImageEditCard
            key={image._id}
            image={image}
            onDelete={(image) => setAskDialogOpen(image)}
            onOpen={showImage(image)}
          />
        ))}
      </div>

      <Backdrop className={classes.backdrop} open={!!visibleImage}>
        {visibleImage && (
          <ImageViewer
            previous={getPrevious()}
            next={getNext()}
            current={visibleImage}
            show={(img) => setVisibleImage(img)}
            close={handleClose}
          />
        )}
      </Backdrop>
      <YesNoDialog
        open={!!askDialogOpen}
        onYes={handleDelete}
        onNo={() => setAskDialogOpen(undefined)}
      >
        Oletko nyt aivan varma, että haluat poistaa kyseisen kuvan?
        <br />
        {askDialogOpen?.filename}
      </YesNoDialog>
    </section>
  );
};
