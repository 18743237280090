import React, { FC, useState } from 'react';
import styles from './input.module.css';
export type Props = React.HTMLProps<HTMLInputElement> & {
  label: string;
  value: number | string;
  onChange: (value: number) => void;
};
const Input: FC<Props> = ({ label, value, onChange, ...rest }) => {
  const id = `input-${
    document.querySelectorAll('input[type="number"]').length
  }`;
  const [empty, setEmpty] = useState<boolean>(value === '');

  return (
    <div className={styles.container}>
      <label htmlFor={id} className={styles.label}>
        {label}
      </label>
      <input
        id={id}
        className={styles.input}
        type="number"
        value={empty ? '' : value}
        onChange={(e) => {
          onChange(Number(e.currentTarget.value));
          if (!empty && e.currentTarget.value === '') {
            setEmpty(true);
          } else if (empty && e.currentTarget.value !== '') {
            setEmpty(false);
          }
        }}
        {...rest}
      />
    </div>
  );
};

export default Input;
