import { Autocomplete } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import { TextField } from "@material-ui/core";
import { apiRequest } from "../../utils/apiRequest";

type Props = {
  makeName: string | null;
  onChange: (make: string | null) => void;
};
export default function ModelSelect({ makeName, onChange }: Props) {
  const [models, setModels] = useState<string[]>([]);
  useEffect(() => {
    if (makeName && makeName.length > 2) {
      apiRequest(`/api/models/${makeName}`)
        .then((result: Response) => result.json())
        .then((models: string[]) => setModels(models));
    } else if (models.length > 0) {
      setModels([]);
    }
  }, [makeName, models]);

  const handleChange = (e) => onChange(e.target.value);
  return (
    <Autocomplete
      autoSelect
      options={models}
      onChange={(e, value) => onChange(value)}
      style={{ width: 150, marginRight: "1rem" }}
      freeSolo
      renderInput={(params) => (
        <TextField
          {...params}
          label="Malli"
          variant="outlined"
          fullWidth
          onChange={handleChange}
        />
      )}
    />
  );
}
