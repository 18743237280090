import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import InfoIcon from '@material-ui/icons/Info';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { IVehicle } from '../../store/vehicles/vehicles-models';
import {
  formatAmount,
  formatDate,
  formatNumber,
  formatPrice,
} from '../../util';
import { ReactComponent as Bmw } from './bmw.svg';
import { ReactComponent as Skoda } from './skoda.svg';
import { ReactComponent as Yamaha } from './yamaha.svg';
import { ReactComponent as Mercedes } from './mercedes-benz-9.svg';
import { ReactComponent as Ktm } from './ktm.svg';
import { ReactComponent as Tesla } from './tesla.svg';
import { sumTotalExpenses } from '../../utils/expenses';
import LinkTankkaus from '../LinkTankkaus';
import { Tooltip } from '@material-ui/core';

const calcDocText =
  'Sisältää huolto-, polttoaine-, vero-, vakuutuskulut ja auton hankintahinnan, josta on vähennetty arvioitu myyntihinta';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      display: 'flex',
      position: 'relative',
      margin: '1em 0',
      textAlign: 'left',
      '&:hover': {
        backgroundColor: 'ghostwhite',
      },
    },
    details: {
      display: 'flex',
      flexDirection: 'column',
    },
    content: {
      flex: '1 0 auto',
    },
    cover: {
      width: 151,
    },
    controls: {
      display: 'flex',
      alignItems: 'center',
      paddingLeft: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    license: {
      display: 'flex',
      alignItems: 'center',
    },
    logo: {
      cursor: 'pointer',
      padding: '0 1em',
      display: 'flex',
      alignItems: 'center',
    },
    table: {
      display: 'flex',
      margin: 'auto',
      '@media (max-width:600px)': {
        display: 'none',
      },
    },
    link: {
      display: 'flex',
      backgroundColor: 'blue',
      justifyContent: 'center',
      alignItems: 'center',
      color: '#fff',
      width: '4em',
      '&:hover': {
        color: 'gray',
      },
    },
    tank: {
      position: 'absolute',
      right: '1rem',
      top: '1rem',
    },
  })
);
const getMake = (make: string) => {
  if (make.toLocaleLowerCase() === 'mercedes-benz') {
    return 'MB';
  }
  return make;
};
export default function CarCard(props: { vehicle: IVehicle }) {
  const { vehicle } = props;
  const classes = useStyles();
  const history = useHistory();

  const onCarClick = () => history.push(`/vehicle/${vehicle._id}/info`);

  return (
    <Card className={classes.card}>
      <div className={classes.logo} onClick={() => onCarClick()}>
        <CarLogo make={vehicle.make} />
      </div>
      <div className={classes.details}>
        <CardContent className={classes.content}>
          <Typography component="h5" variant="h5">
            {getMake(vehicle.make)}
          </Typography>
          <Typography variant="subtitle1" color="textSecondary">
            {vehicle.model}
            <br />
            vm. {moment(vehicle.registrationDate).format('YYYY')}
            <br />
            {formatNumber(vehicle.km)} km
          </Typography>
        </CardContent>
      </div>
      <div className={classes.table}>
        <InfoTable vehicle={vehicle} />
      </div>
      <div className={classes.tank}>
        <LinkTankkaus vehicleId={vehicle._id} />
      </div>
    </Card>
  );
}
const sumReducer = (accumulator, service) =>
  accumulator + (service.price ? service.price : 0);
const sumPrice = (services) => services.reduce(sumReducer, 0);

const pricePerKm = (km: number, price: number) => price / km;

export const CarLogo = (props: { make: string }) => {
  switch (props.make.toLocaleLowerCase()) {
    case 'bmw':
      return <Bmw width={100} height={100} />;
    case 'yamaha':
      return <Yamaha width={100} height={100} />;
    case 'skoda':
      return <Skoda width={100} height={100} />;
    case 'ktm':
    case 'kotari':
      return <Ktm width={100} height={100} />;
    case 'mb':
    case 'mersu':
    case 'mercedes-benz':
    case 'mercedesbenz':
    case 'mercedes':
      return <Mercedes width={100} height={100} />;
    case 'tesla':
      return <Tesla width={77.5} height={100} />;
    default:
      return <span>{props.make}</span>;
  }
};
const InfoTable = (props: { vehicle: IVehicle }) => {
  const { vehicle } = props;
  const serviceCost = sumPrice(vehicle.history);
  const usedMileage = vehicle.km - vehicle.purchaseMileage;
  const totalExpenses = sumTotalExpenses(vehicle);
  return (
    <table>
      <tbody>
        <tr>
          <th>Rek.nro</th>
          <td>{vehicle.registrationNumber}</td>
        </tr>
        <tr>
          <th>Hankinta pvm.</th>
          <td>{formatDate(vehicle.purchaseDate)}</td>
        </tr>
        <tr>
          <th>Omaa ajoa</th>
          <td>{formatAmount(usedMileage, 'km')}</td>
        </tr>
        <tr>
          <th>Kulut yhteensä</th>
          <td>
            {formatPrice(totalExpenses.sumCost)}
            <Tooltip title={calcDocText}>
              <InfoIcon style={{ fontSize: '1rem' }} />
            </Tooltip>
          </td>
        </tr>
        <tr>
          <th>Kulut / km</th>
          <td>{formatPrice(pricePerKm(usedMileage, totalExpenses.sumCost))}</td>
        </tr>
        <tr>
          <th>Huoltoja</th>
          <td>{formatAmount(vehicle.history.length)}</td>
        </tr>
        <tr>
          <th>Vakuutus + vero</th>
          <td>{formatPrice(totalExpenses.insuranceAndTaxCost)}</td>
        </tr>
        <tr>
          <th>Polttoainekulut</th>
          <td>{formatPrice(totalExpenses.fuelCost)}</td>
        </tr>
        <tr>
          <th>huoltokulut</th>
          <td>{formatPrice(serviceCost)}</td>
        </tr>
      </tbody>
    </table>
  );
};
