import { Action } from "redux";
import { Types } from "./main-actions";
import { MainState } from "./main-models";

const initialState: MainState = {
  initialized: false,
};

export const main = (state: MainState = initialState, action: Action) => {
  switch (action.type) {
    case Types.Initialize:
      return {
        ...state,
        initialized: true,
      };
    default:
      return state;
  }
};
