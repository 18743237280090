export interface IJobs {
  [jobName: string]: IJob;
}

export interface IJob {
  title: string;
  mileage?: { warn: number; replace: number };
  age?: { warn: number; replace: number };
}

export function getJob(jobName: keyof typeof Jobs) {
  return Jobs[jobName];
}

// Do not remove jobs unless all the relations from the database is also removed.
export const Jobs: IJobs = {
  battery: {
    title: 'Akku',
    age: {
      warn: 8,
      replace: 10,
    },
  },
  engineOil: {
    title: 'Moottoriöljy',
    mileage: {
      warn: 15000,
      replace: 20000,
    },
    age: {
      warn: 1,
      replace: 1,
    },
  },
  transmissionOil: {
    title: 'Vaihdelaatikon öljy',
    mileage: {
      warn: 60000,
      replace: 100000,
    },
  },
  frontDiffOil: {
    title: 'Etuperän öljy',
    mileage: {
      warn: 40000,
      replace: 50000,
    },
  },
  rearDiffOil: {
    title: 'Perän öljy',
    mileage: {
      warn: 40000,
      replace: 50000,
    },
  },
  transferBoxOil: {
    title: 'Jakolaatikon öljy',
    mileage: {
      warn: 40000,
      replace: 50000,
    },
  },
  engineOilFilter: {
    title: 'Öljynsuodin',
    mileage: {
      warn: 15000,
      replace: 20000,
    },
    age: {
      warn: 1,
      replace: 1,
    },
  },
  transmissionOilFilter: {
    title: 'Vaihdelaatikon suodatin',
  },
  fuelFilter: {
    title: 'Polttoainesuodin',
    mileage: {
      warn: 80000,
      replace: 100000,
    },
  },
  microFilter: {
    title: 'Mikrofiltteri',
    mileage: {
      warn: 20000,
      replace: 30000,
    },
    age: {
      warn: 1,
      replace: 2,
    },
  },
  airFilter: {
    title: 'Imuilmansuodatin',
    mileage: {
      warn: 30000,
      replace: 50000,
    },
  },
  frontBrakePads: {
    title: 'Etujarrupalat',
    mileage: {
      warn: 70000,
      replace: 80000,
    },
  },
  rearBrakePads: {
    title: 'Takajarrupalat',
    mileage: {
      warn: 70000,
      replace: 80000,
    },
  },
  handbrake: {
    title: 'Käsijarrukengät',
  },
  handbrakeCable: {
    title: 'Käsijarrun vaijeri',
  },
  brakeFluid: {
    title: 'Jarruneste',
    age: {
      warn: 1,
      replace: 2,
    },
  },
  waterPump: {
    title: 'Vesipumppu',
    mileage: {
      warn: 200000,
      replace: 2500000,
    },
  },
  oilPump: {
    title: 'Öljypumppu',
  },
  alternator: {
    title: 'Laturi',
    mileage: {
      warn: 200000,
      replace: 250000,
    },
  },
  starterMotor: {
    title: 'Starttimoottori',
  },
  turbo: {
    title: 'Turbo',
    mileage: {
      warn: 250000,
      replace: 300000,
    },
  },
  engineTimingChain: {
    title: 'Jakopään hihna/ketju',
  },
  engineTimingGuide: {
    title: 'Jakopään kiristin/ohjurit',
  },
  sparkPlugs: {
    title: 'Sytytystulpat',
    mileage: {
      warn: 160000,
      replace: 200000,
    },
  },
  ignitionCoils: {
    title: 'Puolat',
    age: {
      warn: 150000,
      replace: 200000,
    },
  },
  radiator: {
    title: 'Jäähdytin',
    mileage: {
      warn: 200000,
      replace: 230000,
    },
    age: {
      warn: 12,
      replace: 15,
    },
  },
  coolant: {
    title: 'Jäähdytinneste',
    age: {
      warn: 3,
      replace: 4,
    },
  },
  thermostat: {
    title: 'Termostaatti',
    age: {
      warn: 8,
      replace: 10,
    },
  },
  windowRegulator: {
    title: 'Ikkunan nostinmekanismi',
  },
  oxygenSensors: {
    title: 'Lambda-anturit',
    mileage: {
      warn: 160000,
      replace: 200000,
    },
  },
  rearWheelBearing: {
    title: 'Takapyörien laakerit',
    mileage: {
      warn: 160000,
      replace: 200000,
    },
  },
  frontWheelBearing: {
    title: 'Etupyörien laakerit',
    mileage: {
      warn: 160000,
      replace: 200000,
    },
  },
  rearBrakeDiscs: {
    title: 'Takajarrulevyt',
    mileage: {
      warn: 150000,
      replace: 170000,
    },
  },
  frontBrakeDiscs: {
    title: 'Etujarrulevyt',
    mileage: {
      warn: 150000,
      replace: 170000,
    },
  },
  clutchDiscs: {
    title: 'Kytkin',
    mileage: {
      warn: 140000,
      replace: 150000,
    },
  },
  exhaust: {
    title: 'Pakoputki',
    mileage: {
      warn: 300000,
      replace: 350000,
    },
  },
  dpf: {
    title: 'Diesel hiukkassuodin (DPF)',
    mileage: {
      warn: 280000,
      replace: 300000,
    },
  },
  frontControlArms: {
    title: 'Etutukivarret',
    mileage: {
      warn: 200000,
      replace: 2500000,
    },
  },
  airConditionService: {
    title: 'Ilmastoinnin huolto',
    mileage: {
      warn: 40000,
      replace: 50000,
    },
    age: {
      warn: 2,
      replace: 3,
    },
  },
  glowPlugs: {
    title: 'Hehkutulpat',
    mileage: {
      warn: 300000,
      replace: 600000,
    },
  },
  valveCoverGasket: {
    title: 'Venttiilikopan tiiviste',
    age: {
      warn: 5,
      replace: 7,
    },
  },
  vanosGaskets: {
    title: 'Vanoksen tiivisteet',
    age: {
      warn: 10,
      replace: 12,
    },
  },
  oilFilterHousingGasket: {
    title: 'Öljyn suodattimen jalan tiiviste',
    age: {
      warn: 5,
      replace: 7,
    },
  },
  wheelAirbag: {
    title: 'Ratin airbag',
    age: {
      warn: 13,
      replace: 15,
    },
  },
  frontRightAirbag: {
    title: 'Pelkääjän airbag',
    age: {
      warn: 13,
      replace: 15,
    },
  },
  wheelAlignment: {
    title: 'Pyörien suuntaus',
    age: {
      warn: 10,
      replace: 12,
    },
  },
  rearShocks: {
    title: 'Takaiskunvaimentimet',
    age: {
      warn: 8,
      replace: 10,
    },
    mileage: {
      warn: 150000,
      replace: 160000,
    },
  },
  frontShocks: {
    title: 'Etuiskunvaimentimet',
    age: {
      warn: 8,
      replace: 10,
    },
    mileage: {
      warn: 150000,
      replace: 160000,
    },
  },
  rearSprings: {
    title: 'Takajouset',
    age: {
      warn: 10,
      replace: 15,
    },
    mileage: {
      warn: 200000,
      replace: 250000,
    },
  },
  frontSprings: {
    title: 'Etujouset',
    age: {
      warn: 10,
      replace: 15,
    },
    mileage: {
      warn: 200000,
      replace: 250000,
    },
  },
  breatherValve: {
    title: 'Huohotin',
  },
  serpentineBelt: {
    title: 'Moniurhahina',
    age: {
      warn: 8,
      replace: 10,
    },
    mileage: {
      warn: 150000,
      replace: 170000,
    },
  },
  disaBMW: {
    title: 'Disaventtiili',
    age: {
      warn: 10,
      replace: 12,
    },
  },
  transmissionStickBushing: {
    title: 'Vaihdekepin puslat',
    age: {
      warn: 10,
      replace: 12,
    },
    mileage: {
      warn: 200000,
      replace: 250000,
    },
  },
  flywheel: {
    title: 'Vauhtipyörä',
    mileage: {
      warn: 200000,
      replace: 300000,
    },
  },
  frontLowerArmRight: {
    title: 'Etu alatukivarsi oikea',
  },
  frontLowerArmLeft: {
    title: 'Etu alatukivarsi vasen',
  },
  frontBallJointRight: {
    title: 'Alapallonivel oikea',
  },
  frontBallJointleft: {
    title: 'Alapallonivel vasen',
  },
  swayBarLink: {
    title: 'Koiranluut',
  },
  frontAntirollbarBushings: {
    title: 'Kallistuksenvak. kumi etu',
  },
  rearAntirollbarBushings: {
    title: 'Kallistuksenvak. kumi taka',
  },
  rackbarInnerJoint: {
    title: 'Hammastangon sisempi nivel',
  },
  rackbarOuterJoint: {
    title: 'Hammastangon ulompi nivel',
  },
  absSensorFrontLeft: {
    title: 'ABS sensori etu vasen',
  },
  absSensorFrontRight: {
    title: 'ABS sensori etu oikea',
  },
  absSensorRearRight: {
    title: 'ABS sensori taka oikea',
  },
  absSensorRearLeft: {
    title: 'ABS sensori taka vasen',
  },
  rearTrailingArmBushes: {
    title: 'Takatukivarren etummaiset puslat',
  },
  driveShaftBearing: {
    title: 'Kardaanin tukilaakeri',
    mileage: {
      warn: 200000,
      replace: 250000,
    },
  },
  driveShaftFront: {
    title: 'Kardaani etu (4x4)',
  },
  driveShaftRear: {
    title: 'Kardaani taka',
  },
  driveShaftFrontLeft: {
    title: 'Vetoakseli etu vas.',
  },
  driveShaftFrontRight: {
    title: 'Vetoakseli etu oik..',
  },
  driveShaftRearLeft: {
    title: 'Vetoakseli taka vas.',
  },
  driveShaftRearRight: {
    title: 'Vetoakseli taka oik.',
  },
  windShield: {
    title: 'Tuulilasi',
  },
  windScreenWipers: {
    title: 'Tuulilasinpyyhkijät',
    age: {
      warn: 1,
      replace: 2,
    },
  },
} as const;
