import React, { useEffect, useState } from "react";
import MenuBookIcon from "@material-ui/icons/MenuBook";
import { apiRequest } from "../../utils/apiRequest";
import ManualViewCard from "../../components/cards/ManualViewCard";
import { IManual } from "../../store/manuals/manuals-models";

export const ListManuals = () => {
  const [manuals, setManuals] = useState<IManual[]>([]);

  useEffect(() => {
    // TODO reduxify when needed
    apiRequest(`/api/public-manuals`).then((resp) =>
      resp.json().then((json) => setManuals(json))
    );
  }, []);

  return (
    <div>
      <h1>
        <MenuBookIcon /> Ohjeita ja informaatiota
      </h1>
      {manuals.map((manual) => (
        <ManualViewCard key={manual._id} manual={manual} />
      ))}
    </div>
  );
};
