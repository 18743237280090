import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import moment from 'moment';
import React, { FC } from 'react';
import { TankkausType } from '../pages/tankkaus/tankkaus';
import { formatNumber } from '../util';

type Props = {
  tankkaukset: TankkausType[];
  travelled: number;
};

const getAverageConsumptionComparedToLastFullTank = (
  tankkaukset: TankkausType[],
  index: number
) => {
  if (index > 0) {
    const current = tankkaukset[index];
    const last = tankkaukset[index - 1];
    if (last.fullTank && current.fullTank) {
      return `${formatNumber(
        current.km > last.km
          ? current.quantity / ((current.km - last.km) / 100)
          : 0
      )} l / 100 km`;
    }
  }
  return '-';
};
const TankkausTable: FC<Props> = ({ tankkaukset, travelled }) => {
  return (
    <TableContainer>
      <Table aria-label="Tankkaukset">
        <TableHead>
          <TableRow>
            <TableCell>Lukema</TableCell>
            <TableCell align="right">Täyttö</TableCell>
            <TableCell align="right">Ajettu</TableCell>
            <TableCell align="right">Kulutus</TableCell>
            <TableCell align="right">Hinta</TableCell>
            <TableCell align="right">Litrahinta</TableCell>
            <TableCell align="right">Aika</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {tankkaukset.map((row, index) => (
            <TableRow key={row._id}>
              <TableCell component="th" scope="row">
                {formatNumber(row.km)} km
              </TableCell>
              <TableCell align="right">
                {formatNumber(row.quantity)} l
              </TableCell>
              <TableCell align="right">
                {formatNumber(
                  index > 0 ? row.km - tankkaukset[index - 1].km : 0
                )}{' '}
                km
              </TableCell>
              <TableCell align="right">
                {getAverageConsumptionComparedToLastFullTank(
                  tankkaukset,
                  index
                )}
              </TableCell>
              <TableCell align="right">{formatNumber(row.price)} €</TableCell>
              <TableCell align="right">
                {formatNumber(row.price / row.quantity)} €/l
              </TableCell>
              <TableCell align="right">
                {moment(row.createdAt).format('DD.MM.YYYY HH:mm:ss')}
              </TableCell>
            </TableRow>
          ))}
          <TableRow>
            <TableCell>yhteensä</TableCell>
            <TableCell align="right">
              {formatNumber(
                tankkaukset.reduce((acc, cur) => (acc += cur.quantity), 0)
              )}{' '}
              litraa
            </TableCell>
            <TableCell align="right">{formatNumber(travelled)} km</TableCell>
            <TableCell align="right" colSpan={2}>
              {formatNumber(
                tankkaukset.reduce((acc, cur) => (acc += cur.price), 0)
              )}{' '}
              €
            </TableCell>
            <TableCell align="right" />
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TankkausTable;
