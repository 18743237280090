import { FuelTypes } from "./cars";

export const fuelPrice = {
  petrol: {
    e95: {
      title: "95E10",
      price: 1.5,
    },
    e98: {
      title: "98E",
      price: 1.55,
    },
  },
  diesel: {
    d: {
      title: "Diesel",
      price: 1.4,
    },
  },
} as const;

export type FuelType = keyof typeof FuelTypes;
export const getFuelPrice = (fuelType: FuelType) => {
  // TODO better pricing table to support also other fuel types.
  if (fuelType === "diesel") {
    return fuelPrice.diesel.d.price;
  }
  return fuelPrice.petrol.e95.price;
};
