import { apiRequest } from "../../utils/apiRequest";
import { createType } from "../../util";
import { Dispatch } from "../index";
import { Image } from "./image-models";
import { getVehicle } from "../vehicles/vehicles-actions";

const moduleName = "image";
export const Types = {
  setImages: createType(moduleName, "imagesSet"),
} as const;

const backendResultToState = (images) =>
  images.map((img: Image) => ({
    _id: img._id,
    url: img.url,
    filename: img.filename,
    size: img.size,
  }));

export const actions = {
  setImages: (images: Image[]) => ({
    type: Types.setImages,
    payload: images,
  }),
};

const getImages = () => async (dispatch: Dispatch) => {
  const result = await apiRequest("/api/image");
  const images = await result.json();
  return dispatch(actions.setImages(backendResultToState(images)));
};

const sendImages = (vehicleId: string, files: any) => async (
  dispatch: Dispatch
) => {
  const formData = new FormData();
  for (let x = 0; x < files.length; x++) {
    formData.append("files", files.item(x)!);
  }
  const response = await fetch(`/api/vehicle/${vehicleId}/image`, {
    method: "POST",
    body: formData,
  }).catch((err) => {
    console.error("image send error", err.message);
  });
  if (!response) {
    return;
  }
  const result = await response.json();
  if (!result) alert("Oh dang, no images saved");

  const entries = formData.entries();
  for (let pair of entries) {
    formData.delete(pair[0]);
  }
  return dispatch(getVehicle(vehicleId));
};
export const ImageActions = {
  getImages,
  sendImages,
};
