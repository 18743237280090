import React, { FC } from 'react';
import { IService, IVehicle } from 'store/vehicles/vehicles-models';
import styles from './vehicleServicesTable.module.css';
import { formatDate, formatNumber, formatPrice } from '../../util';
import { getJob } from '../../fixtures/jobs';

export const VehicleServicesTable: FC<{ services: IService[] }> = ({
  services,
}) => (
  <div className={styles.container}>
    <div className={styles.head}>
      <div className={styles.label}>Pvm</div>
      <div className={styles.label}>Km</div>
      <div className={styles.label}>Tiedot</div>
      <div className={styles.label}>Osat</div>
      <div className={styles.label}>Hinta</div>
    </div>
    {services.map((service) => (
      <div className={styles.body}>
        <div className={styles.col}>
          <div className={styles.value}>{formatDate(service.date)}</div>
        </div>
        <div className={styles.col}>
          <div className={styles.value}>
            {formatNumber(parseInt(service.km))} km
          </div>
        </div>
        <div className={styles.col}>
          <div className={styles.value}>{service.description}</div>
        </div>
        <div className={styles.col}>
          <div className={styles.value}>
            {service.tags.map((t) => getJob(t).title).join(', ')}
          </div>
        </div>
        <div className={styles.col}>
          <div className={styles.value}>
            {formatPrice(parseInt(service.price))}
          </div>
        </div>
      </div>
    ))}
  </div>
);
