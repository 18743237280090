import { Action } from "redux";

import { types } from "./app-actions";
import { AppState, IBackendVersion } from "./app-models";

const initialState: AppState = {
  snack: "",
  version: {
    backend: {
      version: "",
      buildDate: "",
    },
    ui: {
      version: process.env.REACT_APP_VERSION || "",
    },
  },
};

interface MyAction extends Action {
  payload: any;
}

export const app = (state: AppState = initialState, action: MyAction) => {
  switch (action.type) {
    case types.version:
      return {
        ...state,
        version: {
          ...state.version,
          backend: action.payload as IBackendVersion,
        },
      };
    case types.snack:
      return {
        ...state,
        snack: action.payload,
      };

    default:
      return state;
  }
};
