import { ApplicationState } from "../index";
import { createSelector } from "reselect";
import { AppState } from "./app-models";

const appState = (state: ApplicationState): AppState | undefined =>
  state.app !== undefined ? state.app : undefined;

const getVersion = createSelector(appState, (appState) => {
  if (appState && appState.version) {
    return appState.version.ui.version;
  }
  return "";
});

const getUiVersion = createSelector(appState, (appState) => {
  if (appState && appState.version) {
    return appState.version.ui.version;
  }
  return "";
});

const snack = createSelector(appState, (appState) => {
  return appState && appState.snack;
});

export const AppSelectors = {
  getVersion,
  getUiVersion,
  snack,
};
