import React, { FC } from 'react';
import styles from './columns.module.css';

const Column: FC<{ title: string }> = ({ children, title }) => (
  <div>
    <div className={styles.title}>{title}</div>
    <div className={styles.content}>{children}</div>
  </div>
);
const Container: FC = ({ children }) => {
  return <div className={styles.container}>{children}</div>;
};

export { Column, Container };
