import LocalGasStationIcon from '@material-ui/icons/LocalGasStation';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';

const LinkTankkaus: FC<{ add?: boolean; vehicleId?: string }> = ({
  add = false,
  vehicleId = '',
}) => (
  <Link to={`/tankkaukset/${vehicleId}${add ? '/add' : ''}`}>
    <LocalGasStationIcon />
  </Link>
);

export default LinkTankkaus;
