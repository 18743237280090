import React, { FC } from 'react';
import styles from './atCalcForm.module.css';

/**
 * AT aikalaskuri laskee pätkien alkamisajat
 * AT asemien siiirtymäajat. Maastokokeella ei oikeastaan ole mitään tekemistä AT:n kanssa.
 * Esim AT1 -> AT2 välillä voi olla useita maastokokeita.
 *
 * Lähtötiedot:
 * - alkamisaika
 * - pätkien määrä
 * - pätkien pituudet esim 1:02, 1:30, 1:45
 *
 * Laskenta:
 *  Esim. jos lähto tapahtuu klo 09:00 ja korttiin on kirjattu:
 *  AT1 0:00
 *  AT2 1:30
 *  AT3 0:30
 *  Tarkoittaa tämä, että AT1 asemalta lähtö tapahtuu klo 09:00. AT2 asemalta lähtö tapahtuu 09:00 + 01:30, eli 10:30.
 *  AT3 asemalta lähtö tapahtuu 10:30 + 0:30, eli 11:00.
 */

type At = {
  name: string;
  duration: string;
};

type FormProps = {
  startTime: string;
  atTimes: At[];
  setStartTime: (startTime: string) => void;
  setAtTimes: (at: At[]) => void;
  setDriveTime: (driveTime: string) => void;
  driveTime: string;
};


const AtCalcForm: FC<FormProps> = ({atTimes, setAtTimes, setStartTime, startTime, setDriveTime, driveTime}: FormProps) => {

  return (<form className={styles.form} name="at-start-time-calculation">
    <div className={styles.times}>
      <div className={styles.startTime}>
        <label htmlFor="startTime">Lähtöaika</label>
        <input type="time" id="startTime" value={startTime}
               onChange={(e) => setStartTime(e.target.value)}/>
      </div>
      <div className={styles.driveTime}>
        <label htmlFor="driveTime">Ajoaika</label>
        <input type="time" id="driveTime" value={driveTime}
               onChange={(e) => setDriveTime(e.target.value)}/>
      </div>
      <button type="button" onClick={() => setAtTimes([...atTimes, {
        name: `AT${atTimes.length + 1}`,
        duration: driveTime
      }])}>Lisää AT
      </button>
    </div>

    <h2>AT ajoajat</h2>
    {atTimes.map((at, index) => (
        <div key={index}>
          <label htmlFor={`atDuration${index}`}>{`AT${index + 1}: `}</label>
          <input type="time" id={`atDuration${index}`} value={at.duration}
                 onChange={(e) => setAtTimes([...atTimes.slice(0, index), {
                   ...at,
                   name: `AT${index + 1}`,
                   duration: e.target.value
                 }, ...atTimes.slice(index + 1)])}/>
        </div>))}
  </form>);
}

export {AtCalcForm};
export type {At}
