import { createSelector } from "reselect";
import { getJob, IJobs } from "../../fixtures/jobs";
import { sortBy } from "../../util";
import { sumExpenses, sumTotalExpenses } from "../../utils/expenses";
import { ApplicationState } from "../index";
import { IService, IServiceJob, IVehicle } from "./vehicles-models";

const sortByString = <T>(field: keyof T, field2?: keyof T) => (
  a: T,
  b: T
): number => {
  const strA = String(a[field]);
  const strB = String(b[field]);
  const result = strA.localeCompare(strB, "fi");
  if (result === 0 && field2 !== undefined) {
    const strA2 = String(a[field2]);
    const strB2 = String(b[field2]);
    return strA2.localeCompare(strB2, "fi");
  }
  return result;
};

const sortByNumber = <T>(field: keyof T) => (a: T, b: T): number => {
  if (a[field] < b[field]) return 1;
  else if (a[field] > b[field]) return -1;
  else return 0;
};

const getService = (
  currentVehicle: IVehicle | undefined,
  jobName: keyof IJobs
) => {
  if (currentVehicle) {
    const history = currentVehicle.history
      .filter((h) => h.tags && h.tags.find((t) => t === jobName))
      .sort(sortBy("km"))
      .reverse();
    if (history.length > 0) {
      return history[0];
    }
  }
};

const getVehicleList = (state: ApplicationState): IVehicle[] | undefined =>
  state.vehicles.vehicles !== undefined
    ? state.vehicles.vehicles.sort(sortByString("make", "model"))
    : undefined;

const getPublicVehicleList = (
  state: ApplicationState
): Partial<IVehicle>[] | undefined =>
  state.vehicles.publicVehicles !== undefined
    ? state.vehicles.publicVehicles.sort(sortByString("make", "model"))
    : undefined;

const currentVehicle = (state: ApplicationState): IVehicle | undefined =>
  state.vehicles.currentVehicle !== undefined
    ? state.vehicles.currentVehicle
    : undefined;

const getHistory = createSelector(currentVehicle, (currentVehicle) =>
  currentVehicle !== undefined
    ? currentVehicle.history.sort(sortByNumber<IService>("km"))
    : undefined
);

const getVehicle = createSelector(
  currentVehicle,
  (currentVehicle) => currentVehicle
);

const getLatestServices = createSelector(currentVehicle, (currentVehicle) => {
  const jobs: IServiceJob[] = [];

  if (currentVehicle) {
    currentVehicle.serviceTags.forEach((jobName: keyof IJobs) => {
      const service = getService(currentVehicle, jobName);
      const job = getJob(jobName);
      if (job) {
        jobs.push({ job, service });
      }
    });
  }
  return jobs.sort((a: IServiceJob, b: IServiceJob) =>
    a.job.title.localeCompare(b.job.title, "fi")
  );
});

const getExpenses = createSelector(currentVehicle, (currentVehicle) =>
  currentVehicle !== undefined ? sumExpenses(currentVehicle) : []
);

const getTotalExpenses = createSelector(currentVehicle, (currentVehicle) =>
  currentVehicle !== undefined ? sumTotalExpenses(currentVehicle) : undefined
);

const getImages = createSelector(
  currentVehicle,
  (currentVehicle) => currentVehicle?.images
);

export const VehicleSelectors = {
  getVehicleList,
  getPublicVehicleList,
  getVehicle,
  getHistory,
  getLatestServices,
  getExpenses,
  getTotalExpenses,
  getImages,
};
