import { createType } from '../../util';
import { apiRequest } from '../../utils/apiRequest';
import { Dispatch, GetState } from '../index';
import { IManual } from '../manuals/manuals-models';
import { IImage, IPart, IService, IVehicle } from './vehicles-models';

const moduleName = 'vehicles';

export const types = {
  addVehicle: createType(moduleName, 'addVehicle'),
  setVehicles: createType(moduleName, 'setVehicles'),
  setPublicVehicles: createType(moduleName, 'setPublicVehicles'),
  setCurrentVehicle: createType(moduleName, 'setCurrentVehicle'),
  addVehicleHistory: createType(moduleName, 'addVehicleHistory'),
  addVehiclePart: createType(moduleName, 'addVehiclePart'),
  addVehicleManual: createType(moduleName, 'addVehicleManual'),
  updateVehiclePart: createType(moduleName, 'updateVehiclePart'),
  updateVehicleManual: createType(moduleName, 'updateVehicleManual'),
  updateCurrentVehicleHistory: createType(
    moduleName,
    'updateCurrentVehicleHistory'
  ),
  deleteOneCurrentVehicleHistory: createType(
    moduleName,
    'deleteOneCurrentVehicleHistory'
  ),
};

export const actions = {
  addVehicle: (vehicle: IVehicle) => ({
    type: types.addVehicle,
    payload: vehicle,
  }),

  setVehicles: (vehicles: IVehicle[]) => ({
    type: types.setVehicles,
    payload: vehicles,
  }),
  setPublicVehicles: (vehicles: IVehicle[]) => ({
    type: types.setPublicVehicles,
    payload: vehicles,
  }),
  setCurrentVehicle: (vehicle?: IVehicle) => ({
    type: types.setCurrentVehicle,
    payload: vehicle,
  }),
  addVehicleHistory: (history: IService) => ({
    type: types.addVehicleHistory,
    payload: history,
  }),
  addVehiclePart: (part: IPart) => ({
    type: types.addVehiclePart,
    payload: part,
  }),
  addVehicleManual: (manual: IManual) => ({
    type: types.addVehicleManual,
    payload: manual,
  }),
  updateVehiclePart: (part: IPart) => ({
    type: types.updateVehiclePart,
    payload: part,
  }),
  updateVehicleManual: (manual: IManual) => ({
    type: types.updateVehicleManual,
    payload: manual,
  }),
  updateCurrentVehicleHistory: (history: IService) => ({
    type: types.updateCurrentVehicleHistory,
    payload: history,
  }),
  deleteOneCurrentVehicleHistory: (historyId: string) => ({
    type: types.deleteOneCurrentVehicleHistory,
    payload: historyId,
  }),
};
// export const getService = (vehicleId: string) => (dispatch: Dispatch) => {};
// export const loadVehicles = () => async (dispatch: Dispatch) => {
// };

export const unsetVehicle = () => actions.setCurrentVehicle();

export const addVehicle = (vehicle: Partial<IVehicle>) => async (
  dispatch: Dispatch
) => {
  const response = await apiRequest(`/api/vehicle`, {
    body: JSON.stringify(vehicle),
    method: 'POST',
  }).catch((err) => {
    alert(err.message);
    console.error(err);
  });
  if (response && response.ok) {
    const newVehicle: IVehicle = await response.json();
    dispatch(actions.addVehicle(newVehicle));
    return newVehicle;
  }
  throw Error('invalid response');
};

export const addVehicleHistory = (vehicleId: string, history: any) => async (
  dispatch: Dispatch
) => {
  const response = await apiRequest(`/api/vehicle-history/${vehicleId}`, {
    body: JSON.stringify(history),
    method: 'POST',
  }).catch((err) => {
    alert(err.message);
    console.error(err);
  });
  if (response && response.ok) {
    const newHistory = await response.json();
    dispatch(actions.addVehicleHistory(newHistory));
    return newHistory;
  }
  throw new Error('invalid response');
};

export const addVehiclePart = (part: IPart) => async (
  dispatch: Dispatch,
  getState: GetState
) => {
  const vehicle = getState().vehicles.currentVehicle;
  if (!vehicle) {
    throw new Error('No current vehicle, unable to add part.');
  }
  const response = await apiRequest(`/api/vehicle-parts/${vehicle._id}`, {
    body: JSON.stringify(part),
    method: 'POST',
  }).catch((err) => {
    alert(err.message);
    console.error(err);
  });
  if (response && response.ok) {
    const newPart = await response.json();
    dispatch(actions.addVehiclePart(newPart));
    return newPart;
  }
  throw new Error('invalid response');
};

export const addVehicleManual = (manual: IManual) => async (
  dispatch: Dispatch,
  getState: GetState
) => {
  const vehicle = getState().vehicles.currentVehicle;
  if (!vehicle) {
    throw new Error('No current vehicle, unable to add part.');
  }
  const response = await apiRequest(`/api/vehicle-manuals/${vehicle._id}`, {
    body: JSON.stringify(manual),
    method: 'POST',
  }).catch((err) => {
    alert(err.message);
    console.error(err);
  });
  if (response && response.ok) {
    const newManual = await response.json();
    dispatch(actions.addVehicleManual(newManual));
    return newManual;
  }
  throw new Error('invalid response');
};

export const getVehicle = (vehicleId: string) => async (dispatch: Dispatch) => {
  const response = await apiRequest(`/api/vehicle/${vehicleId}`).catch(
    (err) => {
      alert(err.message);
      console.error(err);
    }
  );
  if (response && response.ok) {
    const vehicle = await response.json();
    dispatch(actions.setCurrentVehicle(vehicle));
    return vehicle;
  }
  throw Error('invalid response');
};

export const getVehicles = () => async (dispatch: Dispatch) => {
  const response = await apiRequest(`/api/vehicle`).catch((err) => {
    alert(err.message);
    console.error(err);
  });
  if (response && response.ok) {
    const vehicles = await response.json();
    dispatch(actions.setVehicles(vehicles));
    return vehicles;
  }
  throw Error('invalid response');
};

export const getPublicVehicles = () => async (dispatch: Dispatch) => {
  const response = await apiRequest(`/api/public-vehicle`).catch((err) => {
    alert(err.message);
    console.error(err);
  });
  if (response && response.ok) {
    const vehicles = await response.json();
    dispatch(actions.setPublicVehicles(vehicles));
    return vehicles;
  }
  throw Error('invalid response');
};

export const updateCurrentVehicle = (vehicle: IVehicle) => async (
  dispatch: Dispatch
) => {
  const response = await apiRequest(`/api/vehicle/${vehicle._id}`, {
    body: JSON.stringify(vehicle),
    method: 'PUT',
  }).catch((err) => {
    alert(err.message);
    console.error(err);
  });
  if (response && response.ok) {
    const vehicle = await response.json();
    dispatch(actions.setCurrentVehicle(vehicle));
    return vehicle;
  }
  throw Error('invalid response');
};

export const patchCurrentVehicle = (vehiclePatch: Partial<IVehicle>) => async (
  dispatch: Dispatch,
  getState: GetState
) => {
  const currentVehicle = getState().vehicles.currentVehicle;
  if (!currentVehicle) {
    throw new Error('No selected vehicle in state');
  }
  const response = await apiRequest(`/api/vehicle/${currentVehicle._id}`, {
    body: JSON.stringify(vehiclePatch),
    method: 'PATCH',
  }).catch((err) => {
    alert(err.message);
    console.error(err);
  });
  if (response && response.ok) {
    const vehicle = await response.json();
    dispatch(actions.setCurrentVehicle(vehicle));
    return vehicle;
  }
  throw Error('invalid response');
};

export const patchVehicle = (
  vehicleId: string,
  vehiclePatch: Partial<IVehicle>
) => async (dispatch: Dispatch) => {
  const response = await apiRequest(`/api/vehicle/${vehicleId}`, {
    body: JSON.stringify(vehiclePatch),
    method: 'PATCH',
  }).catch((err) => {
    alert(err.message);
    console.error(err);
  });
  if (response && response.ok) {
    const vehicle = await response.json();
    dispatch(actions.setCurrentVehicle(vehicle));
    return vehicle;
  }
  throw Error('invalid response');
};

export const updateHistory = (vehicleId: string, history: IService) => async (
  dispatch: Dispatch
) => {
  const response = await apiRequest(`/api/vehicle-history/${vehicleId}`, {
    body: JSON.stringify(history),
    method: 'PUT',
  }).catch((err) => {
    alert(err.message);
    console.error(err);
  });
  if (response && response.ok) {
    dispatch(actions.updateCurrentVehicleHistory(history));
    return history;
  }
  throw Error('invalid response');
};

export const deleteHistory = (vehicleId: string, historyId: string) => async (
  dispatch: Dispatch
) => {
  const response = await apiRequest(
    `/api/vehicle-history/${vehicleId}/${historyId}`,
    {
      method: 'DELETE',
    }
  ).catch((err) => {
    alert('Delete error: ' + err.message);
    console.error(err);
  });
  if (response && response.ok) {
    await response.json();
    dispatch(actions.deleteOneCurrentVehicleHistory(historyId));
  } else {
    throw Error('invalid response');
  }
};

export const patchPart = (vehicleId: string, part: Partial<IPart>) => async (
  dispatch: Dispatch
) => {
  const response = await apiRequest(`/api/vehicle-parts/${vehicleId}`, {
    method: 'PATCH',
    body: JSON.stringify(part),
  }).catch((err) => {
    alert(err.message);
    console.error(err);
  });
  if (response && response.ok) {
    const part = await response.json();
    return dispatch(actions.updateVehiclePart(part));
  }
  throw Error('invalid response');
};

export const updateManual = (vehicleId: string, manual: IManual) => async (
  dispatch: Dispatch
) => {
  const response = await apiRequest(`/api/vehicle-manuals/${vehicleId}`, {
    method: 'PUT',
    body: JSON.stringify(manual),
  }).catch((err) => {
    alert(err.message);
    console.error(err);
  });
  if (response && response.ok) {
    // TODO Fix server to return a proper updated manual object.
    // TODO Then use that object to update the redux state.
    // TODO Currently server just returns mongo result which not very useful.
    return dispatch(actions.updateVehicleManual(manual));
  }
  throw Error('invalid response');
};

export const deleteManual = (vehicleId: string, manual: IManual) => async (
  dispatch: Dispatch
) => {
  const response = await apiRequest(
    `/api/vehicle-manuals/${vehicleId}/${manual._id}`,
    {
      method: 'DELETE',
    }
  ).catch((err) => {
    alert(err.message);
    console.error(err);
  });
  if (response && response.ok) {
    return dispatch(getVehicle(vehicleId));
  }
  throw Error('invalid response');
};

export const deleteImage = (vehicleId: string, image: IImage) => async (
  dispatch: Dispatch
) => {
  const response = await apiRequest(
    `/api/vehicle/${vehicleId}/image/${image._id}`,
    {
      method: 'DELETE',
    }
  ).catch((err) => {
    alert(err.message);
    console.error(err);
  });
  if (response && response.ok) {
    return dispatch(getVehicle(vehicleId));
  }
  throw Error('invalid response');
};

export const retirePart = (vehicleId: string, partId: string) => async (
  dispatch: Dispatch
) => dispatch(patchPart(vehicleId, { _id: partId, retired: true }));
