import { Autocomplete } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import { TextField, TextFieldProps } from "@material-ui/core";
import { apiRequest } from "../../utils/apiRequest";

type Props = {
  onChange: (make: string | null) => void;
} & TextFieldProps;
export default function MakeSelect({ onChange }: Props) {
  const [models, setModels] = useState<string[]>([]);
  useEffect(() => {
    apiRequest("/api/models")
      .then((result: Response) => result.json())
      .then((models: string[]) => setModels(models));
  }, []);

  const handleChange = (e) => onChange(e.target.value);
  return (
    <Autocomplete
      autoSelect
      options={models}
      onChange={(e, value) => onChange(value)}
      style={{ width: 200, marginRight: "1rem" }}
      freeSolo
      renderInput={(params) => (
        <TextField
          {...params}
          label="Merkki"
          variant="outlined"
          fullWidth
          onChange={handleChange}
        />
      )}
    />
  );
}
