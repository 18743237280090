import React, { FC } from 'react';
import styles from './checkbox.module.css';
export type Props = React.HTMLProps<HTMLInputElement> & {
  label: string;
  checked: boolean;
  onChange: (value: boolean) => void;
};
const Checkbox: FC<Props> = ({ label, checked, onChange, ...rest }) => {
  const id = `checkbox-${
    document.querySelectorAll('input[type="checkbox"]').length
  }`;

  return (
    <div className={styles.container}>
      <label htmlFor={id} className={styles.label}>
        {label}
      </label>
      <input
        id={id}
        className={styles.input}
        type="checkbox"
        checked={checked}
        onChange={() => onChange(!checked)}
        {...rest}
      />
    </div>
  );
};

export default Checkbox;
