import { EmojiPeople } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { useDispatchTs } from 'store';
import { logout } from 'store/auth/auth-actions';

const useStyles = makeStyles({
  container: {
    width: '100%',
    height: '100%',
    marginTop: '3em',
  },
});

export const LogoutPage = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatchTs();
  const logoutAndRedirect = async () => {
    await dispatch(logout());
    history.push('/');
  };
  useEffect(() => {
    setTimeout(logoutAndRedirect, 2500);
  });

  return (
    <div className={classes.container}>
      <h1>
        Heippa... <EmojiPeople />
      </h1>
    </div>
  );
};
