import { createSelector } from 'reselect';
import { ApplicationState } from '../index';
import { User } from './auth-models';

const currentUser = (state: ApplicationState): User | undefined =>
  state.auth?.user;

const getLoggedInUser = createSelector(currentUser, (user) => user);

export const AuthSelectors = { getLoggedInUser };
