import { createType } from "../../util";
import { IBackendVersion } from "./app-models";
import { Dispatch } from "../index";
import { apiRequest } from "../../utils/apiRequest";

const moduleName = "app";
const versionLocalStorageKey = "version";

export const types = {
  version: createType(moduleName, "version"),
  snack: createType(moduleName, "snack"),
};

const getOldVersion = () => {
  try {
    const version = window.localStorage.getItem(versionLocalStorageKey);
    if (version !== null) {
      return JSON.parse(version) as IBackendVersion;
    }
  } catch (e) {}
};
const updateVersion = (version: IBackendVersion) =>
  window.localStorage.setItem(versionLocalStorageKey, JSON.stringify(version));

export const AppActions = {
  setVersion: (payload: IBackendVersion) => ({
    type: types.version,
    payload,
  }),
  setSnack: (payload: any) => ({
    type: types.snack,
    payload,
  }),
};

export const fetchVersion = () => async (
  dispatch: Dispatch
): Promise<IBackendVersion | undefined> => {
  const response = await apiRequest("/api/version");
  if (response.ok) {
    const result: IBackendVersion = await response.json();
    dispatch(AppActions.setVersion(result));
    const oldVersion = getOldVersion();
    if (
      oldVersion &&
      new Date(result.buildDate) > new Date(oldVersion.buildDate)
    ) {
      dispatch(AppActions.setSnack("newVersion"));
      updateVersion(result);
    } else if (!oldVersion) {
      updateVersion(result);
    }

    return result;
  }
  console.warn("Error getting version", response);
};
