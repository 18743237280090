import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { createStyles } from "@material-ui/styles";
import * as React from "react";
import MarkdownView from "react-showdown";
import { IManual } from "../../store/manuals/manuals-models";

const useStyles = makeStyles(() =>
  createStyles({
    card: {
      minWidth: 275,
      margin: "1em",
    },
    bullet: {
      display: "inline-block",
      margin: "0 2px",
      transform: "scale(0.8)",
    },
    title: {
      fontSize: 14,
    },
    pos: {
      marginBottom: 12,
    },
    buttons: {
      marginBottom: "1em",
    },
    doc: {
      backgroundColor: "#e6e9ec",
      padding: "1em",
    },
    column: {
      flexBasis: "33.3%",
    },
    heading: {},
    tags: {
      color: "gray",
    },
    details: {
      width: "100%",
      textAlign: "left",
    },
  })
);

interface IManualCardProps {
  manual: IManual;
}

function ManualViewCard({ manual }: IManualCardProps) {
  const classes = useStyles();

  return (
    <>
      <ExpansionPanel>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <div className={classes.column}>
            <Typography className={classes.heading}>{manual.name}</Typography>
          </div>
          <div className={classes.column}>
            <Typography className={classes.tags}>
              {manual.tags.join(", ")}
            </Typography>
          </div>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <div className={classes.details}>
            <div className={classes.doc}>
              <MarkdownView markdown={manual.doc as string} />
            </div>
          </div>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </>
  );
}

export default ManualViewCard;
