import { Action } from 'redux';
import { IManual } from '../manuals/manuals-models';
import { types } from './vehicles-actions';
import { IPart, IService, IVehiclesState } from './vehicles-models';

const initialState: IVehiclesState = {};

interface MyAction extends Action {
  payload?: any;
}

export const vehicles = (
  state: IVehiclesState = initialState,
  action: MyAction
) => {
  switch (action.type) {
    case types.addVehicle:
      const vehicles = Array.from(state.vehicles ?? []);
      vehicles.push(action.payload);
      return {
        ...state,
        vehicles,
      };
    case types.setVehicles:
      return {
        ...state,
        vehicles: action.payload,
      };
    case types.setPublicVehicles:
      return {
        ...state,
        publicVehicles: action.payload,
      };
    case types.setCurrentVehicle:
      if (action.payload === undefined) {
        return { ...state, currentVehicle: undefined };
      }
      return {
        ...state,
        currentVehicle: action.payload,
      };
    case types.addVehicleHistory: {
      const newHistory = action.payload;
      let history: IService[];
      if (state.currentVehicle && state.currentVehicle.history) {
        history = Array.from(state.currentVehicle.history);
        history.push(newHistory);
      } else {
        history = [newHistory];
      }

      return {
        ...state,
        currentVehicle: {
          ...state.currentVehicle,
          history,
        },
      };
    }
    case types.addVehiclePart: {
      const part: IPart = action.payload;
      if (state.currentVehicle === undefined) {
        return state;
      }
      const parts = Array.from(state.currentVehicle.parts || []);
      parts.push(part);
      return {
        ...state,
        currentVehicle: {
          ...(state.currentVehicle),
          parts,
        },
      };
    }
    case types.addVehicleManual: {
      const manual: IManual = action.payload;
      if (state.currentVehicle === undefined) {
        return state;
      }
      const manuals = Array.from(state.currentVehicle.manuals || []);
      manuals.push(manual);
      return {
        ...state,
        currentVehicle: {
          ...(state.currentVehicle),
          manuals,
        },
      };
    }
    case types.updateCurrentVehicleHistory: {
      const newHistory: IService = action.payload;

      let history: IService[] | undefined = undefined;
      if (state.currentVehicle && state.currentVehicle.history) {
        history = Array.from(state.currentVehicle.history);
        let index = history.findIndex(
          (service) => service._id === newHistory._id
        );
        history[index] = newHistory;
        console.log('update index', index);
      }
      if (history !== undefined) {
        return {
          ...state,
          currentVehicle: {
            ...state.currentVehicle,
            history,
          },
        };
      }
      return state;
    }
    case types.deleteOneCurrentVehicleHistory: {
      const partToDelete: string = action.payload;
      if (state.currentVehicle === undefined) {
        alert('Ei voi muuttaa ajoneuvon huoltojen tietoja.');
        throw new Error('Unable to find currentVehicle to delete history');
      }
      const history = Array.from(state.currentVehicle.history);
      const idx = history.findIndex((p) => p._id === partToDelete);
      history.splice(idx, 1);

      const newState = {
        ...state,
        currentVehicle: {
          ...state.currentVehicle,
          history,
        },
      };
      return newState;
    }
    case types.updateVehiclePart: {
      const partToUpdate: IPart = action.payload;
      if (state.currentVehicle === undefined) {
        alert('Ei voi muuttaa ajoneuvon osien tietoja.');
        throw new Error('Unable to find currentVehicle to update parts');
      }
      const parts = Array.from(state.currentVehicle.parts);
      const idx = parts.findIndex((p) => p._id === partToUpdate._id);

      if (idx !== undefined) {
        parts[idx] = partToUpdate;
      }
      const newState = {
        ...state,
        currentVehicle: {
          ...state.currentVehicle,
          parts,
        },
      };

      return newState;
    }
    case types.updateVehicleManual: {
      const manualToUpdate: IManual = action.payload;
      if (state.currentVehicle === undefined) {
        alert('Ei voi muuttaa ajoneuvon osien tietoja.');
        throw new Error('Unable to find currentVehicle to update parts');
      }
      const manuals = Array.from(state.currentVehicle.manuals);
      const idx = manuals.findIndex((p) => p._id === manualToUpdate._id);

      if (idx !== undefined) {
        manuals[idx] = manualToUpdate;
      }
      const newState = {
        ...state,
        currentVehicle: {
          ...state.currentVehicle,
          manuals,
        },
      };

      return newState;
    }
    default:
      return state;
  }
};
