import React from "react";
import { makeStyles } from "@material-ui/styles";
import { CreateUser } from "./CreateUser";
import { LoginForm } from "./LoginForm";

const useStyles = makeStyles({
  container: {
    width: "100%",
    height: "100%",
  },
});

export const LoginPage = () => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <LoginForm />
      <CreateUser />
    </div>
  );
};
