import React from "react";
import { Switch } from "react-router";
import { Route } from "react-router-dom";
import { ListManuals } from "./ListManuals";

export const Manuals = () => (
  <Switch>
    <Route path="/manuals" exact component={ListManuals}></Route>
  </Switch>
);
