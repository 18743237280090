import {
  IExpenseRowData,
  IService,
  ITotalExpenseRowData,
  IVehicle,
} from '../store/vehicles/vehicles-models';
import { default as moment } from 'moment';
import { getFuelPrice } from '../fixtures/fuel';

const sum = (fieldName: keyof IService) => (acc: number, cur: IService) =>
  acc + (cur[fieldName] ? Number(cur[fieldName]) : 0);

export const sumExpenses = (vehicle: IVehicle): IExpenseRowData[] => {
  const firstYear = moment(vehicle.purchaseDate).year();
  const currentYear = moment().year();
  const expenses: IExpenseRowData[] = [];
  for (let year = currentYear; year > firstYear; --year) {
    const thisYearHistory = vehicle.history.filter(
      (s) => year === moment(s.date).year()
    );
    const serviceCost = thisYearHistory.reduce(sum('price'), 0);
    const insuranceAndTaxCost = vehicle.insuranceCost + vehicle.taxCost;
    expenses.push({
      year,
      serviceCost,
      insuranceAndTaxCost,
      sumCost: serviceCost + insuranceAndTaxCost,
    });
  }
  return expenses;
};

export const sumTotalExpenses = (vehicle: IVehicle): ITotalExpenseRowData => {
  const averageFuelConsumption = vehicle.averageFuelConsumption; // consumption / 100 km
  const ownedHistory = vehicle.history.filter(
    (history) => new Date(history.date) >= new Date(vehicle.purchaseDate)
  );
  const purchaseYear = moment(vehicle.purchaseDate).year();
  const currentYear = moment().year();
  const ownedYears = currentYear - purchaseYear;
  const serviceCost = ownedHistory.reduce(sum('price'), 0);
  const insuranceAndTaxCost =
    ownedYears * (vehicle.insuranceCost + vehicle.taxCost);
  const mileage = vehicle.km - vehicle.purchaseMileage;
  const fuelCost =
    (mileage / 100) * (getFuelPrice(vehicle.fuelType) * averageFuelConsumption);
  const sumCost =
    serviceCost +
    insuranceAndTaxCost +
    fuelCost +
    (vehicle.purchasePrice ?? 0) -
    (vehicle.sellingPriceEstimate ?? 0);

  return {
    year: `${purchaseYear} - ${currentYear}`,
    serviceCost,
    insuranceAndTaxCost,
    fuelCost,
    mileage,
    sumCost,
  };
};
