import { Action } from "redux";

import { Types } from "./image-actions";
import { ImageState } from "./image-models";

const initialState: ImageState = {};

interface MyAction extends Action {
  payload: any;
}

export const image = (state: ImageState = initialState, action: MyAction) => {
  switch (action.type) {
    case Types.setImages:
      return {
        ...state,
        images: action.payload,
      };
    default:
      return state;
  }
};
