import { InputLabel } from "@material-ui/core";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { makeStyles, Theme } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import React, { useState } from "react";
import { Jobs } from "../fixtures/jobs";
import { IService } from "../store/vehicles/vehicles-models";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(),
    width: 200,
  },
  descriptionField: {
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(),
    width: 500,
  },
  dense: {
    marginTop: 19,
  },
  menu: {
    width: 200,
  },
  button: {
    margin: theme.spacing(),
  },
}));

export interface Props {
  onAddVehicleHistory?: (Service) => void;
  handleChange?: (service: IService) => void;
  service?: IService;
}

const EditVehicleHistoryForm = (props: Props) => {
  const { handleChange, service } = props;
  const classes = useStyles();
  const [state, setState] = useState(
    service || {
      km: "",
      date: "",
      description: "",
      price: "",
      tags: [],
    }
  );

  const handleChangeState = (name: keyof IService) => (event: any) => {
    const newState = { ...state };
    newState[name] = event.target.value;
    setState(newState);
    if (handleChange) {
      handleChange(newState);
    }
  };

  return (
    <form className={classes.container} noValidate autoComplete="off">
      <TextField
        id="new-history-km"
        label="Km"
        type={"number"}
        className={classes.textField}
        value={state.km}
        onChange={handleChangeState("km")}
        margin="normal"
      />

      <TextField
        id="new-history-date"
        label="Date"
        type={"date"}
        className={classes.textField}
        value={state.date}
        onChange={handleChangeState("date")}
        margin="normal"
        InputLabelProps={{
          shrink: true,
        }}
      />

      <TextField
        id="new-history-description"
        label="Description"
        type={"textarea"}
        className={classes.descriptionField}
        value={state.description}
        onChange={handleChangeState("description")}
        margin="normal"
      />

      <TextField
        id="new-history-price"
        label="Hinta"
        placeholder={"€"}
        type={"number"}
        className={classes.textField}
        value={state.price}
        onChange={handleChangeState("price")}
        margin="normal"
      />

      <InputLabel htmlFor="new-history-tags">Kategoriat</InputLabel>
      <Select
        multiple
        value={state.tags || []}
        onChange={handleChangeState("tags")}
        input={<Input id="select-multiple" />}
        MenuProps={MenuProps}
      >
        {Object.keys(Jobs)
          .sort((a: string, b: string) =>
            Jobs[a].title.localeCompare(Jobs[b].title, "fi")
          )
          .map((name) => (
            <MenuItem key={name} value={name}>
              {Jobs[name].title}
            </MenuItem>
          ))}
      </Select>
    </form>
  );
};

export default EditVehicleHistoryForm;
