import { fetchVersion } from "../app/app-actions";
import { initAuth } from "../auth/auth-actions";
import { Dispatch } from "../index";

export enum Types {
  Initialize = "main::initialize",
}
export const initialize = () => async (dispatch: Dispatch) => {
  await Promise.all([dispatch(initAuth()), dispatch(fetchVersion())]);
  return dispatch({ type: Types.Initialize });
};
