import { ApplicationState } from "../index";
import { createSelector } from "reselect";
import { TankkausState } from "./tankkaus-models";

const tankkauksetState = (state: ApplicationState): TankkausState | undefined =>
  state.tankkaus !== undefined ? state.tankkaus : undefined;

const tankkaukset = createSelector(tankkauksetState, (appState) => {
  return appState && appState.tankkaus;
});

export const TankkausSelectors = {
  tankkaukset,
};
