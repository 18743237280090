import { createStyles, makeStyles, Theme } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import LinearProgress from '@material-ui/core/LinearProgress';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';
import React, { FC, useEffect } from 'react';
import { useHistory, useRouteMatch } from 'react-router';
import AddVehiclePartForm from '../../components/AddVehiclePartForm';
import ManualList from '../../components/ManualList';
import PartsList from '../../components/PartsList';
import { useDispatchTs, useSelectorTs } from '../../store';
import {
  addVehiclePart,
  getVehicle,
  updateHistory,
} from '../../store/vehicles/vehicles-actions';
import { IPart } from '../../store/vehicles/vehicles-models';
import { VehicleImages } from '../images/VehicleImages';
import ServiceList from '../serviceList/serviceList';
import Settings from '../settings/settings';
import Statistics from '../statistics/statistics';
import { VehicleIndexPage } from '../vehicleIndexPage/VehicleIndexPage';

function TabContainer(props: any) {
  return (
    <Typography component="div" style={{ padding: 8 * 3 }}>
      {props.children}
    </Typography>
  );
}

enum tabPages {
  info = 'info',
  services = 'services',
  parts = 'parts',
  manual = 'manual',
  statistics = 'statistics',
  images = 'images',
  settings = 'settings',
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.paper,
    },
    appBar: {
      boxShadow: 'none',
    },
  })
);

type MatchParams = {
  vehicleId: string;
  tab: string;
};

const VehicleView: FC = () => {
  const classes = useStyles();
  const dispatch = useDispatchTs();
  const history = useHistory();
  const { params } = useRouteMatch<MatchParams>();
  const currentVehicle = useSelectorTs(
    (state) => state.vehicles.currentVehicle
  );
  const { tab: value, vehicleId } = params;

  useEffect(() => {
    dispatch(getVehicle(vehicleId));
  }, [vehicleId, dispatch]);

  if (!currentVehicle) {
    return <LinearProgress />;
  }

  const handleTabChange = (event: any, value: tabPages) => {
    if (currentVehicle && value) {
      history.push(`/vehicle/${currentVehicle._id}/${value}`);
    }
  };

  return (
    <div className={classes.root}>
      <AppBar className={classes.appBar} position="static">
        <Tabs
          value={value}
          onChange={handleTabChange}
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab value={tabPages.info} label="Tietoja" />
          <Tab value={tabPages.services} label="Huoltokirja" />
          <Tab value={tabPages.manual} label="Asennusohjeet" />
          <Tab value={tabPages.statistics} label="Stats" />
          <Tab value={tabPages.images} label="Kuvat" />
          <Tab value={tabPages.settings} label="Auto" />
        </Tabs>
      </AppBar>
      {value === tabPages.info && currentVehicle && (
        <TabContainer>
          <VehicleIndexPage />
        </TabContainer>
      )}
      {value === tabPages.services && currentVehicle && (
        <TabContainer>
          <ServiceList vehicle={currentVehicle} updateHistory={updateHistory} />
        </TabContainer>
      )}
      {value === tabPages.parts && currentVehicle && (
        <TabContainer>
          <AddVehiclePartForm
            onAddVehiclePart={(part: IPart) => addVehiclePart(part)}
          />
          <PartsList vehicle={currentVehicle} />
        </TabContainer>
      )}
      {value === tabPages.manual && currentVehicle && (
        <TabContainer>
          <ManualList vehicle={currentVehicle} />
        </TabContainer>
      )}
      {value === tabPages.statistics && currentVehicle && (
        <TabContainer>
          <Statistics />
        </TabContainer>
      )}
      {value === tabPages.images && (
        <TabContainer>
          <VehicleImages />
        </TabContainer>
      )}
      {value === tabPages.settings && (
        <TabContainer>
          <Settings />
        </TabContainer>
      )}
    </div>
  );
};

export default VehicleView;
