import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { IImage } from "../../store/vehicles/vehicles-models";

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
    margin: "1rem",
  },
  media: {
    height: 140,
  },
});

type Props = {
  image: IImage;
  onDelete: (image: IImage) => void;
  onOpen: () => void;
};

export default function ImageEditCard({ image, onDelete, onOpen }: Props) {
  const classes = useStyles();
  return (
    <Card className={classes.root}>
      <CardActionArea>
        <CardMedia
          className={classes.media}
          image={`/images/${image.url}`}
          title={image.filename}
          onClick={onOpen}
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            {image.filename}
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>
        <Button size="small" color="primary" onClick={() => onDelete(image)}>
          Poista
        </Button>
        <Button size="small" color="primary" disabled>
          Aseta kansikuvaksi
        </Button>
      </CardActions>
    </Card>
  );
}
