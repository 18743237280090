import { apiRequest } from 'utils/apiRequest';
import { createType } from '../../util';
import { AppActions } from '../app/app-actions';
import { Dispatch } from '../index';
import { LoginCredentials, NewUser, User } from './auth-models';

const moduleName = 'auth';

export const types = {
  create: createType(moduleName, 'create'),
  login: createType(moduleName, 'login'),
  logout: createType(moduleName, 'logout'),
};

export const actions = {
  login: (user: User) => ({
    type: types.login,
    payload: user,
  }),
  logout: () => ({
    type: types.logout,
  }),
};

export const createNewUser = (user: NewUser) => async (
  dispatch: Dispatch
): Promise<User | undefined> => {
  const response = await apiRequest('/api/users', {
    method: 'POST',
    body: JSON.stringify(user),
    headers: { 'Content-Type': 'application/json; charset=utf-8' },
  });
  if (response.ok) {
    const result: User = await response.json();
    dispatch(AppActions.setSnack('newUser'));
    setTimeout(() => AppActions.setSnack(''), 6000);
    window.scrollTo({ top: 0 });
    return result;
  }
  console.warn('Error creating user', response);
  dispatch(actions.logout());
};

export const login = (user: LoginCredentials) => async (
  dispatch: Dispatch
): Promise<User | undefined> => {
  const response = await apiRequest('/api/login', {
    method: 'POST',
    body: JSON.stringify(user),
  });
  if (response.ok) {
    const result: User = await response.json();
    dispatch(actions.login(result));
    return result;
  }
  dispatch(actions.logout());
};

export const logout = () => async (dispatch: Dispatch): Promise<void> => {
  const response = await apiRequest('/api/logout');
  if (response.ok) {
    dispatch(actions.logout());
  }
};

export const initAuth = () => async (
  dispatch: Dispatch
): Promise<User | undefined> => {
  const response = await apiRequest('/api/auth');
  if (response.ok) {
    const result = (await response.json()) as User;
    dispatch(actions.login(result));
    return result;
  }
  dispatch(actions.logout());
};
