import React, { useEffect, useRef, useState } from "react";
import { IImage } from "../../store/vehicles/vehicles-models";
import { makeStyles, Theme } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { getImgUrl } from "../../utils/vehicle";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: "flex",
    flexFlow: "row",
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.8)",
    position: "relative",
  },
  control: {
    display: "flex",
    justifyContent: "center",
  },
  previous: {
    minWidth: "50px",
    cursor: "pointer",
  },
  next: {
    minWidth: "50px",
    cursor: "pointer",
  },
  current: {
    width: "100%",
  },
  close: {
    cursor: "pointer",
    position: "absolute",
    top: "1em",
  },

  image: {
    maxWidth: "100%",
  },
}));

export interface ImageViewerProps {
  previous?: IImage;
  next?: IImage;
  current: IImage;
  show: (img: IImage) => void;
  close: () => void;
}

export const ImageViewer = ({
  previous,
  next,
  current,
  show,
  close,
}: ImageViewerProps) => {
  const classes = useStyles();
  const [touchesX, setTouchesX] = useState<number[]>([]);
  const ref = useRef<HTMLDivElement>(null);
  const handleClick = (next) => (e) => {
    e.preventDefault();
    show(next);
  };

  const onTouchMove = (e) => setTouchesX([...touchesX, e.touches[0].screenX]);
  const onTouchStart = (e) => setTouchesX([e.touches[0].screenX]);
  const onTouchEnd = () => {
    if (touchesX.length > 4) {
      if (previous && touchesX[0] < touchesX[touchesX.length - 1]) {
        show(previous);
      } else if (next) {
        show(next);
      }
    }
    setTouchesX([]);
  };

  useEffect(() => {
    const current = ref.current;
    const handleKey = (e) => {
      // e.keyCode 37 left
      // e.keyCode 39 right
      // e.keyCode 27 esc
      if (e.keyCode === 37 && previous) {
        show(previous);
      } else if (e.keyCode === 39 && next) {
        show(next);
      } else if (e.keyCode === 27) {
        close();
      }
    };
    if (current) {
      window.addEventListener("keyup", handleKey);
    }
    return () => {
      if (current) {
        window.removeEventListener("keyup", handleKey);
      }
    };
  }, [close, show, next, previous, current]);

  return (
    <div className={classes.container} ref={ref}>
      <div className={`${classes.control} ${classes.previous}`}>
        {previous && <ChevronLeftIcon onClick={handleClick(previous)} />}
      </div>
      <div
        className={classes.current}
        onTouchMove={onTouchMove}
        onTouchStart={onTouchStart}
        onTouchEnd={onTouchEnd}
      >
        <img
          className={classes.image}
          src={getImgUrl(current.url)}
          alt={current.filename}
        />
      </div>
      <div className={`${classes.control} ${classes.next}`}>
        <CloseIcon className={classes.close} onClick={() => close()} />
        {next && <ChevronRightIcon onClick={handleClick(next)} />}
      </div>
    </div>
  );
};
