import * as React from 'react';
import { useState } from 'react';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { formatDate, formatNumber, formatPrice } from '../../util';

import EditVehicleHistoryForm from '../AddVehicleHistoryForm';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { IService } from '../../store/vehicles/vehicles-models';
import {
  addVehicleHistory,
  deleteHistory,
  updateHistory,
} from '../../store/vehicles/vehicles-actions';
import { useDispatch } from 'react-redux';
import { Fab } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { useDispatchTs } from '../../store';
import Chip from '@material-ui/core/Chip';
import { getJob } from '../../fixtures/jobs';
import styles from './serviceCard.module.css';

interface ServiceCardProps {
  vehicleId: string;
  service: IService;
  updateHistory: (v: string, s: IService) => void;
}

type Props = ServiceCardProps;

function ServiceCard(props: Props) {
  const { service, vehicleId } = props;
  const dispatch = useDispatchTs();
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  const handleDelete = () => dispatch(deleteHistory(vehicleId, service._id!));

  return (
    <>
      <Card className={styles.card}>
        <CardContent className={styles.cardContent}>
          <Typography variant="h5" component="h2" className={styles.title}>
            {formatNumber(Number(service.km))} km
          </Typography>
          <Typography className={styles.pos} color="textSecondary">
            <div className={styles.date}>{formatDate(service.date)}</div>
            {service.price && (
              <div className={styles.price}>
                - {formatPrice(Number(service.price))}
              </div>
            )}
          </Typography>
          <Typography component="p" className={styles.description}>
            {service.description}
          </Typography>
          <Typography component="div">
            {service.tags.map((t) => (
              <Chip key={t} className={styles.chip} label={getJob(t).title} />
            ))}
          </Typography>
        </CardContent>
        <CardActions className={styles.actions}>
          <Fab
            color="primary"
            aria-label="edit"
            size="medium"
            onClick={handleOpen}
          >
            <EditIcon />
          </Fab>

          <Fab
            color="secondary"
            aria-label="delete"
            size="medium"
            onClick={handleDelete}
          >
            <DeleteIcon />
          </Fab>
        </CardActions>
      </Card>
      <EditServiceDialog
        open={open}
        handleClose={handleClose}
        service={service}
        vehicleId={vehicleId}
      />
    </>
  );
}
interface ServiceDialogProps {
  vehicleId: string;
  service: IService;
  handleClose: (e?: any) => void;
  open: boolean;
}

export function EditServiceDialog(props: ServiceDialogProps) {
  const [service, setService] = useState({ ...props.service });
  const dispatch = useDispatch();
  const onSave = () => {
    if (service._id) {
      dispatch(updateHistory(props.vehicleId, service));
    } else {
      dispatch(addVehicleHistory(props.vehicleId, service));
    }
  };

  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">
        Huollon {service._id ? 'editointi' : 'luonti'}
      </DialogTitle>
      <DialogContent>
        <EditVehicleHistoryForm service={service} handleChange={setService} />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setService(props.service);
            props.handleClose();
          }}
          color="secondary"
        >
          Peruuta
        </Button>
        <Button
          onClick={() => {
            onSave();
            props.handleClose();
          }}
          color="primary"
        >
          Tallenna
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ServiceCard;
