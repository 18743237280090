import React, { FC } from 'react';
import { IVehicle } from 'store/vehicles/vehicles-models';
import styles from './vehicleDetailsTable.module.css';
import { formatNumber } from '../../util';

export const VehicleDetailsTable: FC<{ vehicle: IVehicle }> = ({ vehicle }) => (
  <div className={styles.container}>
    <div className={styles.col}>
      <div className={styles.label}>Merkki</div>
      <div className={styles.make}>
        {vehicle.make} {vehicle.model}
      </div>
    </div>
    <div className={styles.col}>
      <div className={styles.label}>Rekisterinumero</div>
      <div className={styles.registration}>{vehicle.registrationNumber}</div>
    </div>
    <div className={styles.col}>
      <div className={styles.label}>Ajokilometrit</div>
      <div className={styles.registration}>{formatNumber(vehicle.km)} km</div>
    </div>
  </div>
);
