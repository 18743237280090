import { Action } from 'redux';

import { types } from './tankkaus-actions';
import { TankkausState } from './tankkaus-models';

const initialState: TankkausState = {
  tankkaus: null,
};

interface MyAction extends Action {
  payload: any;
}

export const tankkaus = (
  state: TankkausState = initialState,
  action: MyAction
) => {
  switch (action.type) {
    case types.set:
      return {
        ...state,
        tankkaus: action.payload,
      };
    default:
      return state;
  }
};
