import Tankkaus from './tankkaus';
import { Route, Switch, useParams } from 'react-router-dom';
import * as React from 'react';
import { FC, useEffect, useState } from 'react';
import { RouterProps } from 'react-router';
import TankkauksetStats from './stats';
import { useDispatchTs } from '../../store';
import { getVehicle } from '../../store/vehicles/vehicles-actions';
import styles from './tankkaus.module.css';
import { CircularProgress } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { VehicleSelectors } from '../../store/vehicles/vehicle-selectors';
import { TankkausSelectors } from '../../store/tankkaus/tankkaus-selectors';
import { get } from '../../store/tankkaus/tankkaus-actions';

const Tankkaukset: FC<RouterProps> = () => {
  const dispatch = useDispatchTs();
  const vehicleId = useParams<{ vehicleId: string }>().vehicleId;
  const vehicle = useSelector(VehicleSelectors.getVehicle);
  const tankkaukset = useSelector(TankkausSelectors.tankkaukset);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const loadVehicleAndTankkaukset = async () => {
      setLoading(true);
      await Promise.all([
        dispatch(getVehicle(vehicleId)),
        dispatch(get(vehicleId)),
      ]);
      setLoading(false);
    };
    if ((!vehicle || !tankkaukset) && !loading) {
      loadVehicleAndTankkaukset();
    }
  }, [dispatch, vehicle, vehicleId, loading, tankkaukset]);

  if (loading) {
    return <CircularProgress disableShrink className={styles.loading} />;
  }
  return (
    <Switch>
      <Route
        exact
        path="/tankkaukset/:vehicleId"
        component={TankkauksetStats}
      />
      <Route exact path="/tankkaukset/:vehicleId/add" component={Tankkaus} />
    </Switch>
  );
};

export default Tankkaukset;
