import React from "react";
import { WithStyles, withStyles } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";
import {
  IPart as PartModel,
  IVehicle,
} from "../store/vehicles/vehicles-models";
import * as moment from "moment";
import { Button } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { retirePart } from "../store/vehicles/vehicles-actions";
import { formatNumber } from "../util";

const styles = {
  root: {
    textAlign: "left" as "left",
  },
};

interface OwnProps extends WithStyles {
  vehicle: IVehicle;
}

type Props = OwnProps;

const useStyles = makeStyles({
  root: {
    backgroundColor: "#e6e9ec",
    margin: "1em",
    width: "80%",
    borderRadius: "10px",
    padding: "1em",
  },
  title: {
    backgroundColor: "hotpink",
    width: "100%",
  },
  doc: {
    backgroundColor: "blue",
    padding: "1em",
    width: "100%",
  },
  tags: {
    border: "1px solid black",
    padding: "1em",
    width: "100%",
  },
  row: {
    display: "grid",
    gridTemplateColumns: "20% 80%",
  },
  rowTitle: {
    fontWeight: "bold",
  },
  rowValue: {},
  controls: {
    display: "grid",
    justifyItems: "end",
  },
});

const getAge = (registrationDate, changeDate): number => {
  if (registrationDate !== "") {
    const carDate = moment.default(registrationDate);
    if (changeDate !== "") {
      const partDate = moment.default(changeDate);
      return moment.default().diff(partDate, "years");
    }
    return moment.default().diff(carDate, "years");
  }
  return -1;
};

const getKm = (carKm, partKm): number => {
  if (carKm) {
    if (partKm) {
      return carKm - partKm;
    }
    return carKm;
  }

  return -1;
};

const Row = ({ title, children }) => {
  const classes = useStyles();
  return (
    <div className={classes.row}>
      <div className={classes.rowTitle}>{title}:</div>
      <div className={classes.rowValue}>{children}</div>
    </div>
  );
};

const has = (v) => v !== undefined && v !== "";

const Part = (props: { part: PartModel; vehicle: IVehicle }) => {
  const { vehicle, part } = props;
  const classes = useStyles();
  const dispatch = useDispatch();

  const retire = () => dispatch(retirePart(vehicle._id, part._id!));

  return (
    <div className={classes.root}>
      <Row title={"Osa"}>{part.name}</Row>
      <Row title={"Käyttöönottoaika"}>
        {moment
          .default(part.changeDate || vehicle.registrationDate)
          .format("DD.MM.Y")}
      </Row>
      <Row title={"Käyttöaika"}>
        {getAge(vehicle.registrationDate, part.changeDate)} vuotta
      </Row>
      <Row title={"käyttökilometrit"}>
        {formatNumber(getKm(vehicle.km, part.changeKm))} km
      </Row>
      {has(part.refNumber) && <Row title={"OEN"}>{part.refNumber}</Row>}
      {has(part.description) && <Row title={"Tiedot"}>{part.description}</Row>}
      <div className={classes.controls}>
        {part.retired !== true && (
          <Button onClick={() => retire()} variant="outlined">
            Poista käytöstä
          </Button>
        )}
      </div>
    </div>
  );
};

const PartsList = (props: Props) => {
  const { classes, vehicle } = props;

  return (
    <div className={classes.root}>
      {vehicle.parts &&
        vehicle.parts.map((part: PartModel) => (
          <Part key={part._id} part={part} vehicle={vehicle} />
        ))}
      {(!vehicle.parts || vehicle.parts.length === 0) && (
        <p>Ei tallennettuja osia</p>
      )}
    </div>
  );
};

export default withStyles(styles)(PartsList);
