import { TextField } from "@material-ui/core";
import * as React from "react";
import ReactMde from "react-mde";
import "react-mde/lib/styles/css/react-mde-all.css";
import * as Showdown from "showdown";
import { IManual } from "../store/manuals/manuals-models";

const converter = new Showdown.Converter({
  tables: true,
  simplifiedAutoLink: true,
  strikethrough: true,
  tasklists: true,
});

type Props = {
  manual?: IManual;
  onChange?: Function;
};

// TODO refactor shady component usage and change handler
export function AddManual(props: Props) {
  const { manual, onChange } = props;
  const [selectedTab, setSelectedTab] = React.useState<"write" | "preview">(
    "write"
  );
  const [currentManual, setManual] = React.useState(
    manual ||
      ({
        name: "",
        doc: "",
        tags: [],
        public: false,
      } as IManual)
  );

  const handleChange = (field) => (e) => {
    const newState = { ...currentManual };
    newState[field] = e.target.value;
    setManual(newState);
    if (onChange) {
      onChange(newState);
    }
  };
  const handleValChange = (name) => (value) =>
    handleChange(name)({ target: { value } });

  return (
    <form className="container">
      <TextField
        id="manual-name"
        label="Nimi"
        value={currentManual.name}
        onChange={handleChange("name")}
        margin="normal"
        fullWidth={true}
      />

      <ReactMde
        value={currentManual.doc}
        onChange={handleValChange("doc")}
        selectedTab={selectedTab}
        onTabChange={setSelectedTab}
        generateMarkdownPreview={(markdown) =>
          Promise.resolve(converter.makeHtml(markdown))
        }
      />
    </form>
  );
}
