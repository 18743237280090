import { Chip, Paper, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { FC } from 'react';
import { useDispatchTs, useSelectorTs } from '../../store';
import { AuthSelectors } from '../../store/auth/auth-selectors';
import { VehicleSelectors } from '../../store/vehicles/vehicle-selectors';
import { patchVehicle } from '../../store/vehicles/vehicles-actions';
import styles from './UserRights.module.css';

const UserRights: FC = () => {
  const currentVehicle = useSelectorTs(VehicleSelectors.getVehicle);
  const user = useSelectorTs(AuthSelectors.getLoggedInUser);
  const dispatch = useDispatchTs();

  if (!currentVehicle || !user) return null;

  const updateUsers = (users: string[]) =>
    dispatch(patchVehicle(currentVehicle._id, { users }));

  return (
    <Paper className={styles.container}>
      <h2>Käyttäjäoikeudet</h2>
      <p>Käyttäjät joilla on luku- ja kirjoitusoikeus auton tietoihin.</p>
      <Autocomplete
        multiple
        id="tags-filled"
        options={currentVehicle.users.map((option) => option)}
        defaultValue={currentVehicle.users}
        freeSolo
        value={currentVehicle.users}
        onChange={(o, v) => updateUsers([...new Set([user.username, ...v])])}
        renderTags={(value: string[], getTagProps) =>
          value.map((option: string, index: number) => (
            <Chip
              variant="outlined"
              label={option}
              {...getTagProps({ index })}
              disabled={user.username === option}
            />
          ))
        }
        renderInput={(params) => (
          <TextField
            {...params}
            variant="filled"
            label="Käyttäjät"
            placeholder="käyttäjänimi"
          />
        )}
      />
    </Paper>
  );
};

export default UserRights;
