import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
type Props = {
  onYes: any;
  onNo: () => void;
  open: boolean;
  children: React.ReactNode;
};
export default function YesNoDialog(props: Props) {
  const { onYes, onNo, open, children } = props;

  return (
    <Dialog
      open={open}
      onClose={onNo}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{"Poista"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {children}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onNo} color="primary">
          Peruuta
        </Button>
        <Button onClick={onYes} color="primary" autoFocus>
          Kyllä
        </Button>
      </DialogActions>
    </Dialog>
  );
}
