import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Table } from "@material-ui/core";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { VehicleSelectors } from "../../store/vehicles/vehicle-selectors";
import { useSelector } from "react-redux";
import Paper from "@material-ui/core/Paper";
import TableContainer from "@material-ui/core/TableContainer";
import { formatAmount, formatPrice } from "../../util";
import { ITotalExpenseRowData } from "../../store/vehicles/vehicles-models";

const useStyles = makeStyles({
  root: {},
  head: {
    fontWeight: "bold",
  },
  h2: {
    textAlign: "left",
  },
});

const ExpenseRow = (props: { expense: any }) => (
  <TableRow>
    <TableCell>{props.expense.year}</TableCell>
    <TableCell>{formatPrice(props.expense.serviceCost)}</TableCell>
    <TableCell>{formatPrice(props.expense.insuranceAndTaxCost)}</TableCell>
    <TableCell>{formatPrice(props.expense.sumCost)}</TableCell>
  </TableRow>
);

/**
 * vuosittaiset huollot
 * vuosittaiset verot + vakuutus
 * vuosittaiset kulut yhteensä
 */
export const Expenses = () => {
  const styles = useStyles();
  const expenses = useSelector(VehicleSelectors.getExpenses);
  return (
    <>
      <h2 className={styles.h2}>Kaikki huollot</h2>
      <TotalExpenses />
      <h2 className={styles.h2}>Vuosikohtaiset huollot</h2>
      <TableContainer component={Paper}>
        <Table className={styles.root}>
          <TableHead>
            <TableRow>
              <TableCell className={styles.head}>Vuosi</TableCell>
              <TableCell className={styles.head}>Huollot</TableCell>
              <TableCell className={styles.head}>Verot + vakuutus</TableCell>
              <TableCell className={styles.head}>Yhteensä</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {expenses.map((expense, index) => (
              <ExpenseRow key={index} expense={expense} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

/**
 * vuosittaiset huollot
 * vuosittaiset verot + vakuutus
 * vuosittaiset kulut yhteensä
 */
export const TotalExpenses = () => {
  const styles = useStyles();
  const expense = useSelector(
    VehicleSelectors.getTotalExpenses
  ) as ITotalExpenseRowData;
  if (!expense) {
    return null;
  }
  return (
    <TableContainer component={Paper}>
      <Table className={styles.root}>
        <TableHead>
          <TableRow>
            <TableCell className={styles.head}>Omistusvuodet</TableCell>
            <TableCell className={styles.head}>Huollot</TableCell>
            <TableCell className={styles.head}>Verot + vakuutus</TableCell>
            <TableCell className={styles.head}>Ajokilometrit</TableCell>
            <TableCell className={styles.head}>Polttoainekulut</TableCell>
            <TableCell className={styles.head}>Yhteensä</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>{expense.year}</TableCell>
            <TableCell>{formatPrice(expense.serviceCost)}</TableCell>
            <TableCell>{formatPrice(expense.insuranceAndTaxCost)}</TableCell>
            <TableCell>{formatAmount(expense.mileage, "km")}</TableCell>
            <TableCell>{formatPrice(expense.fuelCost)}</TableCell>
            <TableCell>{formatPrice(expense.sumCost)}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};
