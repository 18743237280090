import * as React from "react";
import { useSelector } from "react-redux";
import { AppSelectors } from "../store/app/app-selectors";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles({
  footer: {
    position: "absolute",
    width: "100%",
    bottom: 0,
    height: "2em",
    display: "flex",
    backgroundColor: "#caccaa",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "2em",
    borderTop: "1px solid #b9a5a5",
  },
});

export const Footer = () => {
  const version = useSelector(AppSelectors.getVersion);
  const styles = useStyles();
  return (
    <footer className={styles.footer}>
      <div>&copy; Joonas Kallio - Automobile {`v${version}`}</div>
    </footer>
  );
};
