import { Action } from "redux";

import { types } from "./auth-actions";
import { AuthState } from "./auth-models";

const initialState: AuthState = {
  loggedIn: false,
};

interface MyAction extends Action {
  payload: any;
}

export const auth = (state: AuthState = initialState, action: MyAction) => {
  switch (action.type) {
    case types.login:
      return {
        ...state,
        loggedIn: true,
        user: action.payload,
      };
    case types.logout:
      return {
        ...state,
        user: undefined,
        loggedIn: false,
      };
    default:
      return state;
  }
};
