import { WithStyles, withStyles } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";
import React, { useState } from "react";
import { IVehicle } from "../store/vehicles/vehicles-models";
import ManualCard, { EditManualDialog } from "./ManualCard";
import { addVehicleManual } from "../store/vehicles/vehicles-actions";
import { useDispatch } from "react-redux";
import AddIcon from "@material-ui/icons/Add";
import { Fab } from "@material-ui/core";
import { IManual } from "../store/manuals/manuals-models";

const styles = {
  root: {
    textAlign: "left" as "left",
  },
};

interface IOwnProps extends WithStyles {
  vehicle: IVehicle;
}

type Props = IOwnProps;

const useStyles = makeStyles({
  root: {
    width: "100%",
    textAlign: "left",
  },
  add: {
    marginBottom: "1em",
    marginTop: "0",
  },
  title: {
    width: "100%",
  },
  doc: {
    padding: "0.5em",
    backgroundColor: "lightgray",
    border: "1px solid black",
  },
  tags: {
    border: "1px solid black",
  },
});

const ManualList = (props: Props) => {
  const { vehicle } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const onUpdate = () => alert("todo");
  const onAdd = (manual) => dispatch(addVehicleManual(manual));
  const newManual: IManual = {
    name: "",
    doc: "",
    tags: [],
    public: false,
  };
  const [open, setOpen] = useState(false);

  return (
    <div className={classes.root}>
      <div className={classes.add}>
        <Fab color="primary" aria-label="add" onClick={() => setOpen(true)}>
          <AddIcon />
        </Fab>
        <EditManualDialog
          onSave={onAdd}
          manual={newManual}
          open={open}
          handleClose={() => setOpen(false)}
        />
      </div>
      {vehicle.manuals &&
        vehicle.manuals.map((manual: IManual) => (
          <ManualCard
            key={manual._id}
            vehicleId={vehicle._id}
            manual={manual}
            update={onUpdate}
          />
        ))}
      {(!vehicle.manuals || vehicle.manuals.length === 0) && (
        <p>Ei tallennettuja ohjeita</p>
      )}
    </div>
  );
};

export default withStyles(styles)(ManualList);
