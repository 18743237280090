import TextField from "@material-ui/core/TextField";
import React, { useState } from "react";
import { NewUser } from "../../store/auth/auth-models";
import { Button } from "@material-ui/core";
import { createNewUser } from "../../store/auth/auth-actions";
import { useDispatchTs } from "../../store";
import FormControl from "@material-ui/core/FormControl";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles({
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  form: {
    margin: "1em",
    border: "4px double #caccaa",
    borderRadius: "15px",
    padding: "1em",
    width: "50%",
    "@media (max-width:600px)": {
      width: "100%",
      padding: "0.5em",
      margin: "0.1em",
    },
  },
  title: {
    margin: 10,
  },
  error: {
    color: "red",
  },
  input: {
    textDecoration: "none",
    color: "#fff",
    margin: 10,
  },
  button: {
    margin: 10,
  },
  formControl: {
    width: "80%",
    "@media (max-width:600px)": {
      width: "100%",
    },
  },
});

export const CreateUser = () => {
  const [user, setUser] = useState<NewUser>({
    username: "",
    password: "",
    email: "",
    displayName: "",
  });
  const [pwd, setPwd] = useState<string>("");
  const [error, setError] = useState<string | undefined>();
  const classes = useStyles();
  const dispatch = useDispatchTs();
  const createUser = async (e) => {
    e.preventDefault();
    if (pwd !== user.password) {
      setError("PasswordMismatch");
      return;
    }
    const result = await dispatch(createNewUser(user));
    if (result) {
      setUser({ username: "", password: "", email: "", displayName: "" });
      setPwd("");
      setError(undefined);
    } else {
      setError("Error");
    }
  };
  return (
    <div className={classes.container}>
      <form
        className={classes.form}
        autoComplete="on"
        onSubmit={(e) => {
          createUser(e);
        }}
      >
        <h1>Uusi käyttäjä</h1>
        <FormControl className={classes.formControl}>
          <TextField
            required
            label="Käyttäjätunnus"
            autoComplete="usename"
            value={user.username}
            onChange={(e) => setUser({ ...user, username: e.target.value })}
            margin="normal"
            className={classes.input}
            variant="outlined"
          />
          <TextField
            required
            autoComplete="new-password"
            label="Salasana"
            type={"password"}
            value={user.password}
            onChange={(e) => setUser({ ...user, password: e.target.value })}
            margin="normal"
            className={classes.input}
            variant="outlined"
          />
          <TextField
            required
            autoComplete="new-password"
            label="Salasana uudelleen"
            type={"password"}
            value={pwd}
            onChange={(e) => setPwd(e.target.value)}
            margin="normal"
            error={error === "PasswordMismatch"}
            helperText={
              error === "PasswordMismatch" ? "Salasanat eivät täsmää" : ""
            }
            className={classes.input}
            variant="outlined"
          />
          <TextField
            label="Nimi"
            value={user.displayName}
            onChange={(e) => setUser({ ...user, displayName: e.target.value })}
            margin="normal"
            className={classes.input}
            variant="outlined"
          />
          <TextField
            label="Email"
            value={user.email}
            onChange={(e) => setUser({ ...user, email: e.target.value })}
            margin="normal"
            className={classes.input}
            variant="outlined"
          />
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={classes.button}
          >
            Luo käyttäjä
          </Button>
        </FormControl>
      </form>
    </div>
  );
};
