import React, { FC, useState } from 'react';
import styles from './tankkaus.module.css';
import Input from '../../components/form/input';
import { Button, CircularProgress } from '@material-ui/core';
import { useDispatchTs } from '../../store';
import { add } from '../../store/tankkaus/tankkaus-actions';
import {
  getVehicles,
  patchVehicle,
} from '../../store/vehicles/vehicles-actions';
import { useHistory } from 'react-router';
import { VehicleSelectors } from '../../store/vehicles/vehicle-selectors';
import { useSelector } from 'react-redux';
import Checkbox from '../../components/form/checkbox';

export type TankkausType = Omit<CreateTankkaus, 'km'> & {
  _id?: string;
  km: number;
};

type CreateTankkaus = {
  createdAt?: string;
  fullTank: boolean;
  km?: number;
  price: number;
  quantity: number;
  refVehicle?: string;
};

const isValid = ({ quantity, price, km }: CreateTankkaus) =>
  quantity > 0 && price >= 0 && km && km >= 0;

const Tankkaus: FC = () => {
  const dispatch = useDispatchTs();
  const history = useHistory();
  const vehicle = useSelector(VehicleSelectors.getVehicle);
  const [loading, setLoading] = useState<boolean>(false);
  const [tankkaus, setTankkaus] = useState<CreateTankkaus>({
    fullTank: true,
    quantity: 50,
    price: 75,
  });
  if (vehicle?._id && tankkaus.km === undefined) {
    setTankkaus({ ...tankkaus, km: vehicle.km ?? 0 });
  }
  const { km } = vehicle ?? { km: undefined };

  const onSave = async (e) => {
    e.preventDefault();
    if (vehicle && km && (tankkaus?.km ?? 0) > km && isValid(tankkaus)) {
      setLoading(true);
      await dispatch(patchVehicle(vehicle._id, { km: tankkaus.km }));
      await dispatch(
        add({ ...tankkaus, refVehicle: vehicle._id } as TankkausType)
      );
      await dispatch(getVehicles());
      setLoading(false);
      history.push(`/tankkaukset/${vehicle._id}`);
    }
  };

  return (
    <form className={styles.form} onSubmit={onSave} noValidate>
      <h1>Tankkeri</h1>
      {(loading || !vehicle) && (
        <CircularProgress disableShrink className={styles.loading} />
      )}
      {vehicle && (
        <>
          <div className={styles.textField}>
            <Input
              label="Mittarilukema [km]"
              value={tankkaus.km ?? ''}
              onChange={(km) => setTankkaus({ ...tankkaus, km })}
              disabled={loading}
            />
          </div>

          <div className={styles.textField}>
            <Input
              label="Tankkausmäärä [l]"
              value={tankkaus.quantity ?? ''}
              onChange={(quantity) => setTankkaus({ ...tankkaus, quantity })}
              disabled={loading}
            />
          </div>

          <div className={styles.textField}>
            <Input
              label="Hinta [€]"
              value={tankkaus.price ?? ''}
              onChange={(price) => setTankkaus({ ...tankkaus, price })}
              disabled={loading}
            />
          </div>

          <div className={styles.textField}>
            <Checkbox
              label="Tankki täynnä"
              checked={tankkaus.fullTank}
              onChange={(fullTank) => setTankkaus({ ...tankkaus, fullTank })}
              disabled={loading}
            />
          </div>

          <div className={styles.save}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              size="large"
              type={'submit'}
              disabled={
                !km ||
                loading ||
                (tankkaus?.km ?? 0) <= km ||
                !isValid(tankkaus)
              }
            >
              Tallenna
            </Button>
            <Button
              variant="contained"
              color="secondary"
              fullWidth
              size="large"
              type={'button'}
              onClick={() => history.push('/tankkaukset/' + vehicle._id)}
              disabled={loading}
            >
              Peruuta
            </Button>
          </div>
        </>
      )}
    </form>
  );
};

export default Tankkaus;
