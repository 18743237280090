import {
  Container,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { range } from 'ramda';
import React from 'react';

const values = range(0, 16).map((count: number) => [
  5 + count * 0.5,
  155 + count * 10,
  `${165 + count * 10} - ${175 + count * 10}`,
  185 + count * 10,
]);

const TireSizes = () => (
  <Container maxWidth="sm">
    <Paper>
      <h1>Rengas / Vanteet</h1>
      <p>Renkaan kumin leveys suhteessa vanteen leveyteen.</p>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Vanteen leveys</TableCell>
            <TableCell>Min. kumin leveys</TableCell>
            <TableCell>Ideaali leveys</TableCell>
            <TableCell>Max leveys</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {values.map((row) => (
            <TableRow key={row[0]}>
              <TableCell>{row[0]}</TableCell>
              <TableCell>{row[1]} mm</TableCell>
              <TableCell>{row[2]}</TableCell>
              <TableCell>{row[3]} mm</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  </Container>
);

export default TireSizes;
