import { FormControl, FormLabel, Paper, TextField } from '@material-ui/core';
import React, { FC, useState } from 'react';
import { formatNumber } from '../../util';
import styles from './fuelCalc.module.css';

type CalcValues = {
  consume: string;
  travel: string;
  price: string;
};

const calculate = (travel: number, consume: number, price: number) => ({
  price: (travel / 100) * consume * price,
  amount: (travel / 100) * consume,
  mpg: (100 * 0.621371) / (consume * 0.264172),
});

const FuelCalc: FC = () => {
  const [values, setValues] = useState<CalcValues>({
    consume: '0',
    travel: '100',
    price: '1,35',
  });

  const onChangeHandler = (name: keyof CalcValues) => (event) =>
    setValues({ ...values, [name]: event.target.value });

  const { consume, travel, price } = values as CalcValues;

  return (
    <Paper className={styles.paper}>
      <h1>Polttoainelaskuri</h1>
      <form className={styles.form}>
        <FormControl className={styles.control}>
          <FormLabel className={styles.label}>Kulutus [l / 100km]</FormLabel>
          <TextField
            name="consume"
            onChange={onChangeHandler('consume')}
            value={consume}
          />
        </FormControl>

        <FormControl className={styles.control}>
          <FormLabel className={styles.label}>Matka [km]</FormLabel>
          <TextField
            name="travel"
            onChange={onChangeHandler('travel')}
            value={travel}
          />
        </FormControl>

        <FormControl className={styles.control}>
          <FormLabel className={styles.label}>Hinta [€ / l]</FormLabel>
          <TextField
            name="price"
            onChange={onChangeHandler('price')}
            value={price}
          />
        </FormControl>
      </form>
      <Results
        {...calculate(
          Number(travel.replace(',', '.')),
          Number(consume.replace(',', '.')),
          Number(price.replace(',', '.'))
        )}
      />
    </Paper>
  );
};

const Results: FC<{ amount: number; price: number; mpg: number }> = ({
  amount,
  price,
  mpg,
}) => {
  return (
    <div className={styles.result}>
      Hinta: {formatNumber(price)} €<br />
      Polttoainemäärä: {formatNumber(amount)} litraa
      <br />
      Mailia/Gallona: {formatNumber(mpg)} MPG
    </div>
  );
};

export default FuelCalc;
